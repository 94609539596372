import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {IListarDadosBancariosResult} from "interfaces/CommandsResults/DadosBancariosResults/IListarDadosBancariosResult";
import {ToastHelper} from "helpers/ToastHelper";
import ERotas from "enums/ERotas";
import EStrings from "enums/EStrings";
import {DadosBancariosService} from "services/DadosBancariosService";
import {GridColDef} from "@mui/x-data-grid";
import {DataHelper} from "helpers/DataHelper";
import {Breadcrumbs, Button, Card, CardBody} from "@material-tailwind/react";
import {HiOutlineMinus, HiOutlinePencilSquare, HiOutlinePlus} from "react-icons/hi2";
import SpinnerComponent from "components/Spinner";
import ButtonDefault from "components/Buttons/Default";
import TableComponent from "components/Table";
import DadoBancarioFormulario from "../_components/formulario";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import EAcesso from "enums/EAcesso";
import useData from "../../../contexts/dataProvider/useData";

const DadoBancarioListar = () => {
	const navigate = useNavigate();
	const {auth} = useData();
	const tokenVisao = auth?.token;
	const acesso = auth?.acesso?.toString();

	const [isLoadingIndex, setIsLoadingIndex] = useState<boolean>(true);
	const [listaDadosBancarios, setListaDadosBancarios] = useState<IListarDadosBancariosResult[]>([]);
	const [dadoBancario, setDadoBancario] = useState<IListarDadosBancariosResult | null>(null);
	const [tituloModal, setTituloModal] = useState<string>("");

	const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
	const [atualizarLista, setAtualizarLista] = useState<boolean>(false);

	useEffect(() => {
		listarDadosBancarios();
	}, []);

	useEffect(() => {
		listarDadosBancarios();
	}, [atualizarLista]);

	useEffect(() => {
		if (acesso != EAcesso.MASTER.toString()) {
			ToastHelper("warning", EStrings.USUARIO_NAO_AUTENTICADO_ACAO);

			return navigate(-1);
		}
	}, [acesso]);

	const listarDadosBancarios = async () => {
		setIsLoadingIndex(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			return navigate(ERotas.LOGIN);
		}

		try {
			const result = await DadosBancariosService.listar(tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);

				setIsLoadingIndex(false);
				return navigate(ERotas.PRINCIPAL);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);
					setIsLoadingIndex(false);

					return navigate(ERotas.LOGIN);
				}

				ToastHelper("warning", errors[0].message);
				setIsLoadingIndex(false);

				return navigate(ERotas.PRINCIPAL);
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				setIsLoadingIndex(false);
				return navigate(ERotas.PRINCIPAL);
			}

			setListaDadosBancarios(body.data);
			setIsLoadingIndex(false);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			setIsLoadingIndex(false);
			return navigate(ERotas.PRINCIPAL);
		}
	};

	const ativarDesativar = async (token: string) => {
		setIsLoadingIndex(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			setIsLoadingIndex(false);
			return navigate(ERotas.LOGIN);
		}

		try {
			const result = await DadosBancariosService.ativarDesativar({token}, tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);
				setIsLoadingIndex(false);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					setIsLoadingIndex(false);
					return navigate(ERotas.LOGIN);
				}

				ToastHelper("warning", errors[0].message);
				setIsLoadingIndex(false);

				return navigate(ERotas.DADOS_BANCARIOS_LISTAR);
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);
				setIsLoadingIndex(false);

				return navigate(ERotas.DADOS_BANCARIOS_LISTAR);
			}

			setIsLoadingIndex(false);
			ToastHelper("success", "Dado Bancário atualizado com sucesso.");

			await listarDadosBancarios();
		} catch (error) {
			setIsLoadingIndex(false);

			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return navigate(ERotas.PRINCIPAL);
		}
	};

	const handleAbrirModal = (data: IListarDadosBancariosResult | null, titulo: string) => {
		setDadoBancario(data);
		setTituloModal(titulo);
		setIsOpenModal(!isOpenModal);
	};

	const columns: GridColDef[] = [
		{field: "banco", headerName: "Banco", flex: 100},
		{field: "agencia", headerName: "Agência", width: 100},
		{field: "conta", headerName: "Conta", width: 75},
		{
			field: "data_registro", headerName: "Data Registro", width: 150, valueGetter: (params) => {
				return DataHelper.formatDateString(params.value, "DD/MM/YY H:mm");
			}
		},
		{
			field: "status", headerName: "Status", headerAlign: "center", width: 100, align: "center", valueGetter: (params) => {
				return params.value ? "ATIVO" : "INATIVO";
			}
		},
		{
			field: "acoes", headerName: "Ações", headerAlign: "center", width: 100, align: "center", renderCell: (params) => {
				const isInative = params.row.status;

				const handleAbrir = () => {
					handleAbrirModal(params.row, "Editar");
				};

				const handleAtivar = async () => {
					const token = params.row.token;

					await ativarDesativar(token);
				};

				return (
					<>
						<Button
							className={"rounded-full"}
							size={"sm"}
							variant={"gradient"}
							color={"amber"}
							onClick={() => handleAbrir()}
						>
							<HiOutlinePencilSquare/>
						</Button>
						&ensp;
						{isInative &&
							<Button
								className={"rounded-full"}
								size={"sm"}
								variant={"gradient"}
								color={"red"}
								onClick={handleAtivar}
							><HiOutlineMinus/></Button>
						}

						{!isInative &&
							<Button
								className={"rounded-full"}
								size={"sm"}
								variant={"gradient"}
								color={"green"}
								onClick={handleAtivar}
							><HiOutlinePlus/></Button>
						}
					</>
				);
			}
		}
	];

	return (
		<>
			{isLoadingIndex &&
				<SpinnerComponent/>
			}

			<div className="flex w-full flex-row flex-wrap items-center border-blue-gray-50 text-center justify-between mb-5">
				<Breadcrumbs>
					<a className="opacity-60"> Dados Bancários </a>
					<a className="opacity-60"> Listar </a>
				</Breadcrumbs>
				<ButtonDefault onClick={() => navigate(-1)} description={"Voltar"}/>
			</div>
			<Card className="w-100">
				<CardBody className="flex flex-col gap-4">
					<div>
						<ButtonDefault description={"Adicionar"} onClick={() => handleAbrirModal(null, "Adicionar")}/>
					</div>
					<TableComponent columns={columns} rows={listaDadosBancarios}/>
					<DadoBancarioFormulario open={isOpenModal} setOpen={setIsOpenModal} titulo={tituloModal} data={dadoBancario} setAtualizarLista={setAtualizarLista} setIsLoadingIndex={setIsLoadingIndex}/>
				</CardBody>
			</Card>
		</>
	);
};

export default DadoBancarioListar;