import {IBuscarComboboxResult} from "interfaces/CommandsResults/ParametrosResults/IBuscarComboboxResult";

interface TermoCommand {
	nomeLocal: string | null,
	cepLocal: string | null,
	enderecoLocal: string | null,
	numeroLocal: string | null,
	complementoLocal: string | null,
	bairroLocal: string | null,
	cidadeLocal: string | null,
	ufLocal: string | null,
	ufLocalObject: IBuscarComboboxResult | null,
	dataCasamento: Date | null,
	horaCasamento: Date | null,
	rito: string | null,
	ritoObject: IBuscarComboboxResult | null,
	oficial: string | null,
	numeroLivro: string | null,
	paginaLivro: string | null,
	regime: string | null,
	dataHabilitacao: Date | null,
	publicacaoEdital: string | null
}

const createTermoDefault = (overrides: Partial<TermoCommand>): TermoCommand => ({
	nomeLocal: null,
	cepLocal: null,
	enderecoLocal: null,
	numeroLocal: null,
	complementoLocal: null,
	bairroLocal: null,
	cidadeLocal: null,
	ufLocal: null,
	ufLocalObject: null,
	dataCasamento: null,
	horaCasamento: null,
	rito: null,
	ritoObject: null,
	oficial: null,
	numeroLivro: null,
	paginaLivro: null,
	regime: null,
	dataHabilitacao: null,
	publicacaoEdital: null,
	...overrides
});

export {createTermoDefault};
export type {TermoCommand};