import LineChart from "components/Charts/Line";
import {IBuscarChartResult} from "interfaces/CommandsResults/ChartsResults/IBuscarChartResult";
import {useEffect, useState} from "react";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import EStrings from "enums/EStrings";
import ERotas from "enums/ERotas";
import {useNavigate} from "react-router-dom";
import {ChartsService} from "services/ChartsService";
import Card from "./_components/Card";
import {IQuantidade} from "interfaces/CommandsResults/UsuariosResults/IQuantidade";
import {ToastHelper} from "helpers/ToastHelper";
import SpinnerComponent from "components/Spinner";
import Grid from "@mui/material/Grid";
import {UsuariosService} from "../../services/UsuariosService";
import EAcesso from "../../enums/EAcesso";
import useData from "../../contexts/dataProvider/useData";

const Inicio = () => {
	const navigate = useNavigate();
	const {user, auth} = useData();

	const [isLoadingIndex, setIsLoadingIndex] = useState<boolean>(false);
	const [data, setData] = useState<IBuscarChartResult | null>(null);
	const [requerimentos, setRequerimentos] = useState<IQuantidade | null>(null);
	const [termos, setTermos] = useState<IQuantidade | null>(null);

	useEffect(() => {
		buscarEstatisticas();
		buscarGraficos();
	}, []);

	const buscarEstatisticas = async () => {
		setIsLoadingIndex(true);

		try {
			const result = await UsuariosService.buscarEstatisticas(auth?.token ?? "");

			if (!result) {
				LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

				return navigate(ERotas.LOGIN);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					return;
				}

				ToastHelper("warning", errors[0].message);
				return;
			}

			const body = result.data;

			setRequerimentos(body.data.requerimentos);
			setTermos(body.data.termos);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return navigate(-1);
		} finally {
			setIsLoadingIndex(false);
		}
	};

	const buscarGraficos = async () => {
		setIsLoadingIndex(true);

		try {
			if (!auth?.token || auth?.token === "") {
				LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

				return;
			}

			const result = await ChartsService.buscar(auth?.token);

			if (!result) {
				LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					return;
				}

				ToastHelper("warning", errors[0].message);

				return;
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return;
			}

			setData(body.data);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return navigate(-1);
		} finally {
			setIsLoadingIndex(false);
		}
	};

	return (
		<>
			{isLoadingIndex &&
				<SpinnerComponent/>
			}

			<div className={"space-y-2"}>
				{requerimentos !== null &&
					<Grid container spacing={{xs: 2, md: 3}}>
						<Grid item xs={12} sm={6} md>
							<Card titulo={"Requerimentos"} subtitulo={"Pendentes"} quantidade={requerimentos.pendentes} color={"amber"} rota={ERotas.REQUERIMENTO_LISTAR_PENDENTES}/>
						</Grid>

						<Grid item xs={12} sm={6} md>
							<Card titulo={"Requerimentos"} subtitulo={auth?.acesso.toString() == EAcesso.MASTER.toString() ? "Aguardando Conf. de Pagamento" : "Faça sua Edição"} quantidade={requerimentos.aguardando} color={"amber"} rota={ERotas.REQUERIMENTO_LISTAR_AGUARDANDO}/>
						</Grid>

						<Grid item xs={12} sm={6} md>
							<Card titulo={"Requerimentos"} subtitulo={"Aprovados"} quantidade={requerimentos.aprovados} color={"amber"} rota={ERotas.REQUERIMENTO_LISTAR_APROVADOS}/>
						</Grid>

						<Grid item xs={12} sm={6} md>
							<Card titulo={"Requerimentos"} subtitulo={"Reprovados"} quantidade={requerimentos.reprovados} color={"amber"} rota={ERotas.REQUERIMENTO_LISTAR_REPROVADOS}/>
						</Grid>

						<Grid item xs={12} sm={6} md>
							<Card titulo={"Requerimentos"} subtitulo={"Finalizados"} quantidade={requerimentos.finalizados} color={"amber"} rota={ERotas.REQUERIMENTO_LISTAR_FINALIZADOS}/>
						</Grid>
					</Grid>
				}

				{termos !== null &&
					<Grid container spacing={{xs: 2, md: 3}}>
						<Grid item xs={12} sm={6} md>
							<Card titulo={"Termos"} subtitulo={"Pendentes"} quantidade={termos.pendentes} color={"teal"} rota={ERotas.TERMO_LISTAR_PENDENTES}/>
						</Grid>

						<Grid item xs={12} sm={6} md>
							<Card titulo={"Termos"} subtitulo={auth?.acesso.toString() == EAcesso.MASTER.toString() ? "Aguardando Conf. de Pagamento" : "Faça sua Edição"} quantidade={termos.aguardando} color={"teal"} rota={ERotas.TERMO_LISTAR_AGUARDANDO}/>
						</Grid>

						<Grid item xs={12} sm={6} md>
							<Card titulo={"Termos"} subtitulo={"Aprovados"} quantidade={termos.aprovados} color={"teal"} rota={ERotas.TERMO_LISTAR_APROVADOS}/>
						</Grid>

						<Grid item xs={12} sm={6} md>
							<Card titulo={"Termos"} subtitulo={"Reprovados"} quantidade={termos.reprovados} color={"teal"} rota={ERotas.TERMO_LISTAR_REPROVADOS}/>
						</Grid>

						<Grid item xs={12} sm={6} md>
							<Card titulo={"Termos"} subtitulo={"Finalizados"} quantidade={termos.finalizados} color={"teal"} rota={ERotas.TERMO_LISTAR_FINALIZADOS}/>
						</Grid>
					</Grid>
				}

				<Grid container spacing={{xs: 2, md: 3}}>
					<Grid item xs={12}>
						<LineChart titulo={"Requerimento x Termo"} data={data}/>
					</Grid>
				</Grid>
			</div>
		</>
	);
};

export default Inicio;