import React, {Dispatch, SetStateAction, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Dialog, DialogBody, DialogFooter, DialogHeader, Typography} from "@material-tailwind/react";
import ButtonDefault from "components/Buttons/Default";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import EStrings from "enums/EStrings";
import ERotas from "enums/ERotas";
import {ToastHelper} from "helpers/ToastHelper";
import {IListarTermoResult} from "interfaces/CommandsResults/TermosResults/IListarTermoResult";
import {TermosService} from "services/TermosService";
import {IAprovarCancelamentoTermoCommand} from "interfaces/Commands/TermosCommands/IAprovarCancelamentoTermoCommand";
import {IReprovarCancelamentoTermoCommand} from "interfaces/Commands/TermosCommands/IReprovarCancelamentoTermoCommand";
import {BaseHelper} from "helpers/BaseHelper";
import {IAprovarCancelamentoParcialTermoCommand} from "../../../../../interfaces/Commands/TermosCommands/IAprovarCancelamentoParcialTermoCommand";
import useData from "../../../../../contexts/dataProvider/useData";

interface IProps {
	termo?: IListarTermoResult;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setAtualizarLista: Dispatch<SetStateAction<boolean>>;
}

const TermoModalVisualizarCancelamento = (props: IProps) => {
	const {
		termo,
		open,
		setOpen,
		setAtualizarLista
	} = props;

	const navigate = useNavigate();
	const {auth} = useData();
	const tokenVisao = auth?.token;

	const [desabilitarBotao, setDesabilitarBotao] = useState<boolean>(false);

	const handleReprovarCancelamento = async () => {
		setDesabilitarBotao(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			return navigate(ERotas.LOGIN);
		}

		try {
			const params: IReprovarCancelamentoTermoCommand = {
				token: termo?.token ?? ""
			};

			const result = await TermosService.reprovarCancelamento(params, tokenVisao);

			if (!result) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					return navigate(ERotas.LOGIN);
				}

				ToastHelper("warning", errors[0].message);

				setDesabilitarBotao(false);

				return;
			}

			const body = result.data;

			if (!body) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return;
			}

			setDesabilitarBotao(false);
			setOpen(false);

			ToastHelper("success", "Cancelamento reprovado com sucesso.");

			setAtualizarLista(true);
		} catch (error) {
			setDesabilitarBotao(false);

			if (error instanceof Error) {
				ToastHelper("error", error.message);
				setOpen(false);

				return;
			}

			ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			setOpen(false);

			return;
		}
	};

	const handleAprovarCancelamento = async () => {
		setDesabilitarBotao(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			return navigate(ERotas.LOGIN);
		}

		try {
			const params: IAprovarCancelamentoTermoCommand = {
				token: termo?.token ?? ""
			};

			const result = await TermosService.aprovarCancelamento(params, tokenVisao);

			if (!result) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					return navigate(ERotas.LOGIN);
				}

				ToastHelper("warning", errors[0].message);

				setDesabilitarBotao(false);

				return;
			}

			const body = result.data;

			if (!body) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return;
			}

			setDesabilitarBotao(false);
			setOpen(false);

			ToastHelper("success", "Cancelamento aprovado com sucesso.");

			setAtualizarLista(true);
		} catch (error) {
			setDesabilitarBotao(false);

			if (error instanceof Error) {
				ToastHelper("error", error.message);
				setOpen(false);

				return;
			}

			ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			setOpen(false);

			return;
		}
	};

	const handleAprovarCancelamentoParcial = async () => {
		setDesabilitarBotao(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			return navigate(ERotas.LOGIN);
		}

		try {
			const params: IAprovarCancelamentoParcialTermoCommand = {
				token: termo?.token ?? ""
			};

			const result = await TermosService.aprovarCancelamentoParcial(params, tokenVisao);

			if (!result) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					return navigate(ERotas.LOGIN);
				}

				ToastHelper("warning", errors[0].message);

				setDesabilitarBotao(false);

				return;
			}

			const body = result.data;

			if (!body) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return;
			}

			setDesabilitarBotao(false);
			setOpen(false);

			ToastHelper("success", "Cancelamento aprovado com sucesso.");

			setAtualizarLista(true);
		} catch (error) {
			setDesabilitarBotao(false);

			if (error instanceof Error) {
				ToastHelper("error", error.message);
				setOpen(false);

				return;
			}

			ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			setOpen(false);

			return;
		}
	};

	return (
		<Dialog open={open} handler={() => setOpen(!open)} size={"sm"} animate={BaseHelper.AnimationDialog}>
			<DialogHeader>Motivo de Cancelamento # {termo?.id}</DialogHeader>
			<DialogBody className={"space-y-3"}>
				<Typography variant={"small"} className={"font-monospace text-justify"}>
					{termo?.motivo_cancelamento ?? ""}
				</Typography>
				<Typography variant={"small"} className={"font-monospace font-bold text-justify"}>
					Selecione o tipo de cancelamento do termo:
					<ul className={"mt-2 ml-2 list-inside list-disc"}>
						<li>Cancelamento Parcial: Possibilita a criação de um novo termo.</li>
						<li>Cancelamento Total: É realizado o cancelamento do termo e requerimento.</li>
					</ul>
				</Typography>
				<Typography variant={"small"} className={"font-monospace font-bold text-justify"} color={"red"}>
					OBS.: ESTA AÇÃO É IRREVERSÍVEL.
				</Typography>
			</DialogBody>
			<DialogFooter className={"space-x-2"}>
				<ButtonDefault color={"red"} description={"Reprovar"} onClick={handleReprovarCancelamento} desabilitar={desabilitarBotao}/>
				<ButtonDefault color={"brown"} description={"Aprovar Parcial"} onClick={handleAprovarCancelamentoParcial} desabilitar={desabilitarBotao}/>
				<ButtonDefault color={"green"} description={"Aprovar Total"} onClick={handleAprovarCancelamento} desabilitar={desabilitarBotao}/>
			</DialogFooter>
		</Dialog>
	);
};

export default TermoModalVisualizarCancelamento;