import {Input as InputTail} from "@material-tailwind/react";
import classNames from "classnames";
import styles from "./Input.module.scss";
import InputProps from "./_interfaces/InputProps";

const Input = (props: InputProps) => {
	const {
		type = "text",
		label,
		value,
		maxLength,
		uppercase = false,
		lowercase = false,
		required = false,
		readonly = false,
		onChange,
		onBlur
	} = props;

	return (
		<InputTail
			crossOrigin={undefined}
			type={type}
			label={label}
			value={value}
			color={"amber"}
			variant={"outlined"}
			size={"md"}
			maxLength={maxLength}
			onChange={onChange}
			onBlur={onBlur}
			required={required}
			readOnly={readonly}
			className={classNames(styles.input, {
				["uppercase"]: uppercase,
				["lowercase"]: lowercase,
				[styles.readonly]: readonly
			})}
		/>
	);
};

export default Input;