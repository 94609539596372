import {useNavigate} from "react-router-dom";
import React, {FormEvent, useEffect, useState} from "react";
import SpinnerComponent from "components/Spinner";
import {IBuscarTermoResult} from "interfaces/CommandsResults/TermosResults/IBuscarTermoResult";
import EAcesso from "enums/EAcesso";
import {Button, Dialog, DialogFooter, DialogHeader, Textarea, Typography} from "@material-tailwind/react";
import {HiOutlineArrowTopRightOnSquare} from "react-icons/hi2";
import Input from "components/Inputs/Default";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {CepHelper} from "helpers/CepHelper";
import {IBuscarComboboxResult} from "interfaces/CommandsResults/ParametrosResults/IBuscarComboboxResult";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import EStrings from "enums/EStrings";
import ERotas from "enums/ERotas";
import {ParametrosService} from "services/ParametrosService";
import {DatePicker, TimePicker} from "@mui/x-date-pickers";
import {DataHelper} from "helpers/DataHelper";
import {ToastHelper} from "helpers/ToastHelper";
import ButtonDefault from "components/Buttons/Default";
import ButtonSubmit from "components/Buttons/Submit";
import {createNubenteTermoDefault, NubenteTermoResult} from "interfaces/CommandsResults/NubentesResults/NubenteTermoResult";
import {TermosService} from "services/TermosService";
import {PdfHelper} from "helpers/PdfHelper";
import {IBuscarModeloPdfTermoCommand} from "interfaces/Commands/TermosCommands/IBuscarModeloPdfTermoCommand";
import {createTestemunhaTermoDefault, TestemunhaTermoResult} from "interfaces/CommandsResults/TestemunhasResults/TestemunhaTermoResult";
import {ISalvarAtualizarTermoCommand} from "interfaces/Commands/TermosCommands/ISalvarAtualizarTermoCommand";
import TabelaDocumentos from "../../tabelas/listarDocumentos";
import InputDropDragPdf from "components/Inputs/PdfDragDrop";
import {BaseHelper} from "helpers/BaseHelper";
import ListarDocumentosTermoCommand from "interfaces/Commands/DocumentosCommands/ListarDocumentosTermoCommand";
import {IListarDocumentosTermoResult} from "interfaces/CommandsResults/DocumentosResults/IListarDocumentosTermoResult";
import SalvarAtualizarNubenteTermoCommand from "interfaces/Commands/NubentesCommands/SalvarAtualizarNubenteTermoCommand";
import {ISalvarAtualizarTestemunhaTermoCommand} from "interfaces/Commands/TestemunhasCommands/ISalvarAtualizarTestemunhaTermoCommand";
import SalvarAtualizarDocumentoTermoCommand from "interfaces/Commands/DocumentosCommands/SalvarAtualizarDocumentoTermoCommand";
import {DocumentosService} from "services/DocumentosService";
import AccordionNubente from "./_components/accordionNubente";
import AccordionTestemunha from "./_components/accordionTestemunha";
import IRequerimentosAprovadosSemTermoResult from "interfaces/CommandsResults/RequerimentosResults/IRequerimentosAprovadosSemTermoResult";
import TermoAlertaCriacao from "../../cards/alertaCriacao/index";
import {createTermoDefault, TermoCommand} from "./_interfaces/TermoCommand";
import useData from "../../../../../contexts/dataProvider/useData";

interface IProps {
	data?: IBuscarTermoResult;
	requerimento?: IRequerimentosAprovadosSemTermoResult | null;
	textoCriarAtualizar: string;
	novo: boolean;
}

const TermoFormulario = (props: IProps) => {
	const {
		data,
		requerimento,
		textoCriarAtualizar,
		novo
	} = props;
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [atualizarListaDocumentos, setAtualizarListaDocumentos] = useState<boolean>(false);

	const navigate = useNavigate();
	const {auth, user} = useData();
	const tokenVisao = auth?.token;
	const tokenUsuario = user?.token;
	const acesso = auth?.acesso?.toString();

	const [listaDocumentos, setListaDocumentos] = useState<IListarDocumentosTermoResult[]>([]);
	const [listaEstados, setListaEstados] = useState<IBuscarComboboxResult[]>([]);
	const [listaRitos, setListaRitos] = useState<IBuscarComboboxResult[]>([]);
	const [listaAfirmacoes, setListaAfirmacoes] = useState<IBuscarComboboxResult[]>([]);
	const [listaEstadosCivis, setListaEstadosCivis] = useState<IBuscarComboboxResult[]>([]);
	const [listaGeneros, setListaGeneros] = useState<IBuscarComboboxResult[]>([]);
	const [listaTiposIdentidades, setListaTiposIdentidades] = useState<IBuscarComboboxResult[]>([]);
	const [listaNubentes, setListaNubentes] = useState<NubenteTermoResult[]>([]);
	const [listaTestemunhas, setListaTestemunhas] = useState<TestemunhaTermoResult[]>([]);

	const [termo, setTermo] = useState<TermoCommand>(createTermoDefault({}));

	const [fileHabilitacao, setFileHabilitacao] = useState<File[]>([]);
	const [filesModelos, setFilesModelos] = useState<File[]>([]);

	const [openAccordionNubente, setOpenAccordionNubente] = useState<number>(0);
	const [openAccordionTestemunha, setOpenAccordionTestemunha] = useState<number>(0);

	const [openDialog, setOpenDialog] = useState<boolean>(false);
	const [desabilitarBotao, setDesabilitarBotao] = useState<boolean>(false);

	// region EFFECTS
	useEffect(() => {
		buscarCombobox();
	}, [props.data]);

	useEffect(() => {
		if (fileHabilitacao.length > 0) {
			const carregarLista = async () => {
				const lista = [...listaDocumentos];
				const documentoIndex = listaDocumentos.findIndex((documento) => documento.tipo === "HABILITACAO");

				setIsLoading(true);
				const base64String = await BaseHelper.FileToBase64(fileHabilitacao[0]);

				if (documentoIndex === -1) {
					// NOTE:: NOVO DOCUMENTO
					const item: IListarDocumentosTermoResult = {
						id: lista.length + 1,
						token: "",
						tipo: "HABILITACAO",
						descricao: BaseHelper.GetToken(),
						base64: base64String,
						token_solicitante: tokenUsuario ?? "",
						temporario: true
					};

					lista.push(item);
				} else {
					lista[documentoIndex] = {
						...lista[documentoIndex],
						base64: base64String,
						token: "",
						temporario: true
					};
				}

				setListaDocumentos(lista);
				setIsLoading(false);
			};

			carregarLista();
		}
	}, [fileHabilitacao]);

	useEffect(() => {
		if (filesModelos.length > 0) {
			const carregarLista = async () => {
				setIsLoading(true);

				let lista = [...listaDocumentos];

				lista = lista.filter((documento) => {
					return !(
						documento.tipo === "MODELOS" &&
						documento.temporario
					);
				});

				const base64Promises = filesModelos.map(async (file) => {
					const base64String = await BaseHelper.FileToBase64(file);
					const item: IListarDocumentosTermoResult = {
						id: lista.length + 1,
						token: "",
						tipo: "MODELOS",
						descricao: BaseHelper.GetToken(),
						base64: base64String,
						token_solicitante: tokenUsuario ?? "",
						temporario: true
					};
					return item;
				});

				try {
					const items = await Promise.all(base64Promises);
					lista.push(...items);

					setListaDocumentos(lista);
				} catch (error) {
					console.error("Erro ao processar arquivos:", error);
				} finally {
					setIsLoading(false);
				}
			};

			carregarLista();
		}
	}, [filesModelos]);

	useEffect(() => {
		if (atualizarListaDocumentos) {
			listarDocumentos(props.data?.token ?? "");
		}
	}, [atualizarListaDocumentos]);

	useEffect(() => {
		const selectedOption = listaEstados.find((option) => option.sigla === termo.ufLocal);

		handleInputChange("ufLocalObject", selectedOption ?? null);
	}, [termo.ufLocal, listaEstados]);

	useEffect(() => {
		const selectedOption = listaRitos.find((option) => option.sigla === termo.rito);

		handleInputChange("ritoObject", selectedOption ?? null);
	}, [termo.rito, listaRitos]);
	// endregion

	const handleInputChange = (name: string, value: string | IBuscarComboboxResult | Date | null) => {
		setTermo((prevState) => ({
			...prevState,
			[name]: value
		}));
	};

	// region POPULAR DADOS
	const buscarCombobox = async () => {
		setIsLoading(true);

		try {
			if (!tokenVisao || tokenVisao === "") {
				LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);
				setIsLoading(false);

				navigate(ERotas.LOGIN);
				return;
			}

			const result = await ParametrosService.buscarCombobox({relatorio: "TERMO"}, tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);
				setIsLoading(false);

				return navigate(-1);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);
					setIsLoading(false);

					navigate(ERotas.LOGIN);
					return;
				}

				ToastHelper("warning", errors[0].message);
				setIsLoading(false);

				return navigate(-1);
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);
				setIsLoading(false);

				return navigate(-1);
			}

			const data = body.data;

			popularCombobox(data);
			setIsLoading(false);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return navigate(-1);
		}
	};

	const popularCombobox = (dados: IBuscarComboboxResult[]) => {
		const estados = dados.filter(item => item.relatorio.includes("ESTADOS"));
		const ritos = dados.filter(item => item.relatorio.includes("RITOS"));
		const afirmacoes = dados.filter(item => item.relatorio.includes("AFIRMACOES"));
		const estadosCivis = dados.filter(item => item.relatorio.includes("ESTADO_CIVIL"));
		const generos = dados.filter(item => item.relatorio.includes("GENEROS"));
		const tiposIdentidades = dados.filter(item => item.relatorio.includes("TIPOS_IDENTIDADES"));

		setListaEstados(estados);
		setListaRitos(ritos);
		setListaAfirmacoes(afirmacoes);
		setListaEstadosCivis(estadosCivis);
		setListaGeneros(generos);
		setListaTiposIdentidades(tiposIdentidades);

		setIsLoading(false);
		if (data != null) {
			setTermo({
				nomeLocal: data.nome_local,
				cepLocal: data.cep_local,
				enderecoLocal: data.endereco_local,
				numeroLocal: data.numero_local,
				complementoLocal: data.complemento_local,
				bairroLocal: data.bairro_local,
				cidadeLocal: data.cidade_local,
				ufLocal: data.uf_local,
				ufLocalObject: estados.find((option) => option.sigla === data.uf_local) ?? null,
				dataCasamento: DataHelper.converterStringToDate(data.data_casamento),
				horaCasamento: DataHelper.converterHourToDate(data.hora_casamento),
				rito: data.rito,
				ritoObject: ritos.find((option) => option.sigla === data.rito) ?? null,
				oficial: data.oficial,
				numeroLivro: data.numero_livro,
				paginaLivro: data.pagina_livro,
				regime: data.regime,
				dataHabilitacao: DataHelper.converterStringToDate(data.data_habilitacao),
				publicacaoEdital: data.publicacao_edital
			});

			const nubentes = [...listaNubentes];
			if (data.nubentes.length > 0) {
				data.nubentes.forEach((item, index) => {
					nubentes[index] = item;

					nubentes[index].data_falecimento_pai_date = nubentes[index].data_falecimento_pai !== null ? DataHelper.converterStringToDate(nubentes[index].data_falecimento_pai) : null;
					nubentes[index].data_falecimento_mae_date = nubentes[index].data_falecimento_mae !== null ? DataHelper.converterStringToDate(nubentes[index].data_falecimento_mae) : null;

					if (afirmacoes.length > 0) {
						nubentes[index].falecimento_pai_object = afirmacoes.find((option) => option.sigla === nubentes[index].falecimento_pai) ?? null;
						nubentes[index].falecimento_mae_object = afirmacoes.find((option) => option.sigla === nubentes[index].falecimento_mae) ?? null;
					}
				});
			}
			setListaNubentes(nubentes);

			const testemunhas = [...listaTestemunhas];
			for (let i = 0; i < 4; i++) {
				try {
					const testemunha: TestemunhaTermoResult = {
						nome: data.testemunhas[i]?.nome ?? "",
						data_nascimento: data.testemunhas[i]?.data_nascimento ?? "",
						data_nascimento_date: (data.testemunhas[i]?.data_nascimento ?? null) !== null ? DataHelper.converterStringToDate(data.testemunhas[i]?.data_nascimento) : null,
						nacionalidade: data.testemunhas[i]?.nacionalidade ?? "",
						estado_civil: data.testemunhas[i]?.estado_civil ?? "",
						estado_civil_object: estadosCivis.find((option) => option.sigla === (data.testemunhas[i]?.estado_civil ?? "")) ?? null,
						genero: data.testemunhas[i]?.genero ?? "",
						genero_object: generos.find((option) => option.sigla === (data.testemunhas[i]?.genero ?? "")) ?? null,
						tipo_identidade: data.testemunhas[i]?.tipo_identidade ?? "",
						tipo_identidade_object: tiposIdentidades.find((option) => option.sigla === (data.testemunhas[i]?.tipo_identidade ?? "")) ?? null,
						cin: data.testemunhas[i]?.cin ?? null,
						cpf: data.testemunhas[i]?.cpf ?? null,
						documento_identificacao: data.testemunhas[i]?.documento_identificacao ?? null,
						orgao_expedidor: data.testemunhas[i]?.orgao_expedidor ?? "",
						profissao: data.testemunhas[i]?.profissao ?? "",
						cep: data.testemunhas[i]?.cep ?? "",
						endereco: data.testemunhas[i]?.endereco ?? "",
						numero: data.testemunhas[i]?.numero ?? "",
						complemento: data.testemunhas[i]?.complemento ?? "",
						bairro: data.testemunhas[i]?.bairro ?? "",
						cidade: data.testemunhas[i]?.cidade ?? "",
						uf: data.testemunhas[i]?.uf ?? "",
						uf_object: estados.find((option) => option.sigla === (data.testemunhas[i]?.uf ?? "")) ?? null,
						nome_pai: data.testemunhas[i]?.nome_pai ?? "",
						nome_mae: data.testemunhas[i]?.nome_mae ?? "",
						token: data.testemunhas[i]?.token ?? "",
					};

					testemunhas.push(testemunha);
				} catch (e) {
					console.log(e);
				}
			}

			setListaTestemunhas(testemunhas);
		} else {
			const nubentes: NubenteTermoResult[] = [];
			for (let i = 0; i < 2; i++) {
				const nubente = createNubenteTermoDefault({});
				nubente.nome = i == 0 ? requerimento?.primeiro_nubente as string : requerimento?.segundo_nubente as string;
				nubente.token = i == 0 ? requerimento?.token_primeiro_nubente as string : requerimento?.token_segundo_nubente as string;

				nubentes.push(nubente);
			}
			setListaNubentes(nubentes);

			const testemunhas = [...listaTestemunhas];
			for (let i = 0; i < 4; i++) {
				testemunhas[i] = createTestemunhaTermoDefault({});
			}

			setListaTestemunhas(testemunhas);
		}

		if (data !== null && data !== undefined) {
			listarDocumentos(data?.token);
		}
	};

	const listarDocumentos = async (termo: string) => {
		setIsLoading(true);
		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			navigate(ERotas.LOGIN);
			return;
		}

		try {
			setIsLoading(false);

			const params: ListarDocumentosTermoCommand = {
				token: termo
			};

			const result = await DocumentosService.listarDocumentosTermo(params, tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return navigate(-1);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					navigate(ERotas.LOGIN);
					return;
				}

				ToastHelper("warning", errors[0].message);

				return navigate(-1);
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return navigate(-1);
			}

			setListaDocumentos(body.data);
		} catch (error) {
			setIsLoading(true);

			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return navigate(-1);
		}
	};
	// endregion

	const LogToast = (tipo: string, mensagem: string) => {
		ToastHelper(tipo, mensagem);

		setDesabilitarBotao(false);
		setOpenDialog(false);
	};

	const handleOpen = () => {
		setOpenDialog(!openDialog);
	};

	const handleOpenModeloChange = async () => {
		setIsLoading(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			navigate(ERotas.LOGIN);
			return;
		}

		try {
			const params: IBuscarModeloPdfTermoCommand = {
				token: data?.token ?? ""
			};

			const result = await TermosService.buscarModeloPdf(params, tokenVisao);

			if (!result) {
				setIsLoading(false);
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return navigate(-1);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					navigate(ERotas.LOGIN);
					setIsLoading(false);
					return;
				}

				setIsLoading(false);
				ToastHelper("warning", errors[0].message);

				return navigate(-1);
			}

			const body = result.data;

			if (!body) {
				setIsLoading(false);
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return navigate(-1);
			}

			setIsLoading(false);
			PdfHelper.showPdf(body.data.base64);
		} catch (error) {
			setIsLoading(false);

			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return navigate(-1);
		}
	};

	const handleCepLocalChange = async (value: string) => {
		setIsLoading(true);

		value = CepHelper.formatarCep(value);

		handleInputChange("cepLocal", value);

		const result = await CepHelper.buscarCep(value);

		const setValuesEmpty = () => {
			handleInputChange("cepLocal", "");
			handleInputChange("enderecoLocal", "");
			handleInputChange("bairroLocal", "");
			handleInputChange("cidadeLocal", "");
			handleInputChange("ufLocal", "");
		};

		if (result == null) {
			setValuesEmpty();
			setIsLoading(false);

			ToastHelper("warning", EStrings.CEP_INVALIDO);

			return;
		}

		handleInputChange("enderecoLocal", result.logradouro);
		handleInputChange("bairroLocal", result.bairro);
		handleInputChange("cidadeLocal", result.localidade);
		handleInputChange("ufLocal", result.uf);

		setIsLoading(false);
	};

	const enviarFormulario = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setDesabilitarBotao(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			navigate(ERotas.LOGIN);
			return;
		}

		try {
			const documentos = listaDocumentos.filter((item) => item.temporario);
			const documentosCommand: SalvarAtualizarDocumentoTermoCommand[] = [];
			documentos.forEach(item => {

				const documento: SalvarAtualizarDocumentoTermoCommand = {
					token: item.token,
					tipo: item.tipo,
					base64: item.base64
				};

				documentosCommand.push(documento);
			});

			const nubentesCommand: SalvarAtualizarNubenteTermoCommand[] = [];
			listaNubentes.forEach((item) => {
				const nubenteCommand: SalvarAtualizarNubenteTermoCommand = {
					token: item.token,
					nome: item.nome,
					nome_atualizacao: item.nome_atualizacao,
					naturalidade: item.naturalidade,
					nome_mae: item.nome_mae,
					falecimento_mae: item.falecimento_mae_object?.token ?? "",
					data_falecimento_mae: item.data_falecimento_mae_date != null ? DataHelper.getDateTime(item.data_falecimento_mae_date, "YYYY-MM-DD") : null,
					endereco_mae: item.endereco_mae,
					nome_pai: item.nome_pai,
					falecimento_pai: item.falecimento_pai_object?.token ?? "",
					data_falecimento_pai: item.data_falecimento_pai_date != null ? DataHelper.getDateTime(item.data_falecimento_pai_date, "YYYY-MM-DD") : null,
					endereco_pai: item.endereco_pai
				};

				nubentesCommand.push(nubenteCommand);
			});

			const testemunhas = listaTestemunhas.filter((item) => item.nome != "" || item.data_nascimento_date != null);
			const testemunhasCommand: ISalvarAtualizarTestemunhaTermoCommand[] = [];
			testemunhas.forEach((item) => {
				const testemunhaCommand: ISalvarAtualizarTestemunhaTermoCommand = {
					token: item.token,
					nome: item.nome,
					data_nascimento: DataHelper.getDateTime(item.data_nascimento_date, "YYYY-MM-DD"),
					nacionalidade: item.nacionalidade,
					estado_civil: item.estado_civil_object?.token ?? "",
					genero: item.genero_object?.token ?? "",
					tipo_identidade: item.tipo_identidade_object?.token ?? "",
					cin: item.cin,
					cpf: item.cpf,
					documento_identificacao: item.documento_identificacao,
					orgao_expedidor: item.orgao_expedidor,
					profissao: item.profissao,
					cep: item.cep,
					endereco: item.endereco,
					numero: item.numero,
					complemento: item.complemento,
					bairro: item.bairro,
					cidade: item.cidade,
					uf: item.uf_object?.token ?? "",
					nome_mae: item.nome_mae,
					nome_pai: item.nome_pai
				};

				testemunhasCommand.push(testemunhaCommand);
			});

			const params: ISalvarAtualizarTermoCommand = {
				token: props.data?.token ?? "",
				token_requerimento: props.data?.token_requerimento ?? (requerimento?.token ?? ""),
				nome_local: termo.nomeLocal ?? "",
				cep_local: termo.cepLocal ?? "",
				endereco_local: termo.enderecoLocal ?? "",
				numero_local: termo.numeroLocal ?? "",
				complemento_local: termo.complementoLocal ?? "",
				bairro_local: termo.bairroLocal ?? "",
				cidade_local: termo.cidadeLocal ?? "",
				uf_local: termo.ufLocalObject?.token ?? "",
				data_evento: DataHelper.getDateTime(termo.dataCasamento, "YYYY-MM-DD"),
				hora_evento: DataHelper.getDateTime(termo.horaCasamento, "HH:mm") ?? "",
				rito: termo.ritoObject?.token ?? "",
				oficial: termo.oficial ?? "",
				numero_livro: termo.numeroLivro ?? "",
				pagina_livro: termo.paginaLivro ?? "",
				regime: termo.regime ?? "",
				data_habilitacao: DataHelper.getDateTime(termo.dataHabilitacao, "YYYY-MM-DD"),
				publicacao_edital: termo.publicacaoEdital ?? "",
				nubentes: nubentesCommand,
				testemunhas: testemunhasCommand,
				documentos: documentosCommand
			};

			const result = await TermosService.salvarAtualizar(params, tokenVisao);

			if (!result) {
				return LogToast("warning", EStrings.ERRO_RESULT);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				ToastHelper("warning", errors[0].message);

				setOpenDialog(false);
				setDesabilitarBotao(false);

				return;
			}

			const body = result.data;

			if (!body) {
				return LogToast("warning", EStrings.ERRO_BUSCAR_DADOS);
			}

			const data = body.data;

			LogToast("success", `Termo ${params.token == "" ? "criado" : "atualizado"} com sucesso!`);

			setTimeout(() => {
				if (acesso === EAcesso.MASTER.toString()) {
					navigate(`${ERotas.TERMO_VISUALIZAR}/${data.token}`);
				} else if (acesso === EAcesso.CELEBRANTE.toString()) {
					navigate(`${ERotas.TERMO_EDITAR}/${data.token}`);
				} else {
					navigate(-1);
				}
			}, EStrings.TIMEOUT);
		} catch (error) {
			setDesabilitarBotao(false);

			if (error instanceof Error) {
				return LogToast("error", error.message);
			}

			return LogToast("error", EStrings.ERRO_NAO_MAPEADO);
		}
	};

	return (
		<form id={"formTermo"} onSubmit={enviarFormulario} noValidate>
			{isLoading &&
				<SpinnerComponent/>
			}

			<div className={"space-y-4 mb-3"}>
				{acesso === EAcesso.CELEBRANTE.toString() &&
					<TermoAlertaCriacao/>
				}

				<div className={"grid gap-x-6 gap-y-8 sm:grid-cols-12"}>
					{data?.token_solicitante === tokenUsuario && acesso === EAcesso.CELEBRANTE.toString() &&
						<div className={"sm:col-span-12 flex justify-center"}>
							<Button
								variant="gradient"
								color={"light-blue"}
								size={"sm"}
								className="rounded-full flex items-center gap-3"
								onClick={handleOpenModeloChange}
							>
								<HiOutlineArrowTopRightOnSquare className={"h-5 w-5"}/>
								Visualize seu Modelo
							</Button>
						</div>
					}

					{acesso === EAcesso.CELEBRANTE.toString() &&
						<>
							<div className={"sm:col-span-12 justify-center align-center space-y-2"}>
								{data !== null && data !== undefined &&
									<Typography className={"font-normal"} variant={"lead"} color={"teal"}>
										Caso o deseja anexar novos documentos, por favor, anexe-os abaixo:
									</Typography>
								}

								<legend>Habilitação de Casamento</legend>
								<InputDropDragPdf setFiles={setFileHabilitacao} multiple={false}/>
							</div>

							<div className={"sm:col-span-12"}>
								{data !== null && data !== undefined && acesso == EAcesso.CELEBRANTE.toString() &&
									<Typography className={"font-normal"} variant={"lead"} color={"teal"}>
										Caso o Cartório exija modelo, por favor, anexe-os abaixo:
									</Typography>
								}

								<legend>Modelos</legend>
								<InputDropDragPdf setFiles={setFilesModelos} multiple={true}/>
							</div>
						</>
					}
				</div>

				<div className={"sm:col-span-12"}>
					<legend className={"pb-3"}>Documentos Anexados</legend>
				</div>

				<TabelaDocumentos data={listaDocumentos} setData={setListaDocumentos} setIsLoading={setIsLoading} setAtualizarListaDocumentos={setAtualizarListaDocumentos}/>

				<legend className={"pb-3"}>Dados do Evento</legend>
				<div className={"grid gap-x-6 gap-y-8 sm:grid-cols-12"}>
					<div className={"sm:col-span-12"}>
						<Input
							type={"text"}
							label={"Nome do Local"}
							value={termo.nomeLocal ?? undefined}
							onChange={(e) => handleInputChange("nomeLocal", e.target.value)}
						/>
					</div>

					<div className={"sm:col-span-4"}>
						<Input
							type={"text"}
							label={"CEP"}
							value={termo.cepLocal ? CepHelper.formatarCep(termo.cepLocal) : undefined}
							maxLength={9}
							validation={termo.cepLocal != null && termo.cepLocal != ""}
							required={true}
							onChange={(e) => handleInputChange("cepLocal", e.target.value)}
							onBlur={(e) => handleCepLocalChange(e.target.value)}
						/>
					</div>

					<div className={"sm:col-span-6"}>
						<Input
							type={"text"}
							label={"Endereço"}
							value={termo.enderecoLocal ?? undefined}
							validation={termo.enderecoLocal != null && termo.enderecoLocal != ""}
							required={true}
							onChange={(e) => handleInputChange("enderecoLocal", e.target.value)}
						/>
					</div>

					<div className={"sm:col-span-2"}>
						<Input
							type={"text"}
							label={"Número"}
							value={termo.numeroLocal ?? undefined}
							validation={termo.numeroLocal != null && termo.numeroLocal != ""}
							required={true}
							onChange={(e) => handleInputChange("numeroLocal", e.target.value)}
						/>
					</div>

					<div className={"sm:col-span-3"}>
						<Input
							type={"text"}
							label={"Complemento"}
							value={termo.complementoLocal ?? undefined}
							onChange={(e) => handleInputChange("complementoLocal", e.target.value)}
						/>
					</div>

					<div className={"sm:col-span-3"}>
						<Input
							type={"text"}
							label={"Bairro"}
							value={termo.bairroLocal ?? undefined}
							validation={termo.bairroLocal != null && termo.bairroLocal != ""}
							required={true}
							onChange={(e) => handleInputChange("bairroLocal", e.target.value)}
						/>
					</div>

					<div className={"sm:col-span-3"}>
						<Input
							type={"text"}
							label={"Cidade"}
							value={termo.cidadeLocal ?? undefined}
							validation={termo.cidadeLocal != null && termo.cidadeLocal != ""}
							required={true}
							onChange={(e) => handleInputChange("cidadeLocal", e.target.value)}
						/>
					</div>

					<div className={"sm:col-span-3"}>
						<Autocomplete
							options={listaEstados}
							getOptionLabel={(option) => option.descricao}
							value={termo.ufLocalObject}
							size={"small"}
							isOptionEqualToValue={(option, value) =>
								option.token === value.token &&
								option.descricao === value.descricao &&
								option.sigla === value.sigla &&
								option.relatorio === value.relatorio
							}
							onChange={(event, newValue) => handleInputChange("ufLocalObject", newValue)}
							renderInput={(params) => <TextField {...params} label="Estado" required={true}/>}
						/>
					</div>
				</div>

				<legend className={"pb-3"}>Dados do Casamento</legend>
				<div className={"grid gap-x-6 gap-y-8 sm:grid-cols-12"}>
					<div className={"sm:col-span-4"}>
						<DatePicker
							label="Data do Evento"
							format={"dd/MM/yyyy"}
							value={termo.dataCasamento ?? undefined}
							onChange={(e) => handleInputChange("dataCasamento", e)}
							minDate={DataHelper.getNow()}
							maxDate={DataHelper.addDays(90)}
							yearsPerRow={3}
							slotProps={{
								textField: {
									fullWidth: true,
									required: true,
									size: "small"
								}
							}}
						/>
					</div>

					<div className={"sm:col-span-4"}>
						<TimePicker
							label="Hora do Evento"
							format={"HH:mm"}
							value={termo.horaCasamento}
							onChange={(e) => handleInputChange("horaCasamento", e)}
							ampm={false}
							slotProps={{
								textField: {
									fullWidth: true,
									required: true,
									size: "small",
									color: "warning"
								}
							}}
						/>
					</div>

					<div className={"sm:col-span-4"}>
						<Autocomplete
							options={listaRitos}
							getOptionLabel={(option) => option.descricao}
							value={termo.ritoObject}
							size={"small"}
							isOptionEqualToValue={(option, value) =>
								option.token === value.token &&
								option.descricao === value.descricao &&
								option.sigla === value.sigla &&
								option.relatorio === value.relatorio
							}
							onChange={(event, newValue) => handleInputChange("ritoObject", newValue)}
							renderInput={(params) => <TextField {...params} label="Rito" required={true}/>}
						/>
					</div>

					<div className={"sm:col-span-8"}>
						<Input
							type={"text"}
							label={"Nome Oficial do Cartório"}
							value={termo.oficial ?? undefined}
							validation={termo.oficial != null && termo.oficial != ""}
							required={true}
							onChange={(e) => handleInputChange("oficial", e.target.value)}
						/>
					</div>

					<div className={"sm:col-span-4"}>
						<Input
							type={"text"}
							label={"Número do seu livro"}
							value={termo.numeroLivro ?? undefined}
							validation={termo.numeroLivro != null && termo.numeroLivro != ""}
							required={true}
							onChange={(e) => handleInputChange("numeroLivro", e.target.value)}
						/>
					</div>

					<div className={"sm:col-span-4"}>
						<Input
							type={"text"}
							label={"Página do seu livro"}
							value={termo.paginaLivro ?? undefined}
							validation={termo.paginaLivro != null && termo.paginaLivro != ""}
							required={true}
							onChange={(e) => handleInputChange("paginaLivro", e.target.value)}
						/>
					</div>

					<div className={"sm:col-span-4"}>
						<Input
							type={"text"}
							label={"Regime"}
							value={termo.regime ?? undefined}
							validation={termo.regime != null && termo.regime != ""}
							required={true}
							onChange={(e) => handleInputChange("regime", e.target.value)}
						/>
					</div>

					<div className={"sm:col-span-4"}>
						<DatePicker
							label="Data de Habilitação"
							format={"dd/MM/yyyy"}
							value={termo.dataHabilitacao ?? undefined}
							onChange={(e) => handleInputChange("dataHabilitacao", e)}
							yearsPerRow={3}
							slotProps={{
								textField: {
									fullWidth: true,
									required: true,
									size: "small",
									color: "warning"
								}
							}}
						/>
					</div>

					<div className={"sm:col-span-12"}>
						<Textarea
							color={"green"}
							label="Publicação do Edital"
							value={termo.publicacaoEdital ?? undefined}
							variant="outlined"
							size={"md"}
							required={true}
							onChange={(e) => handleInputChange("publicacaoEdital", e.target.value)}
						/>
					</div>
				</div>

				<legend className={"pb-3"}>Dados dos Nubentes</legend>
				<AccordionNubente
					open={openAccordionNubente}
					setOpen={setOpenAccordionNubente}
					listaNubentes={listaNubentes}
					setListaNubentes={setListaNubentes}
					listaAfirmacoes={listaAfirmacoes}
				/>

				<legend className={"pb-3"}>Testemunhas</legend>
				<AccordionTestemunha
					open={openAccordionTestemunha}
					setOpen={setOpenAccordionTestemunha}
					setIsLoading={setIsLoading}
					listaTestemunhas={listaTestemunhas}
					setListaTestemunhas={setListaTestemunhas}
					listaEstadosCivis={listaEstadosCivis}
					listaGeneros={listaGeneros}
					listaEstados={listaEstados}
					listaTiposIdentidades={listaTiposIdentidades}
				/>
			</div>

			<div className="grid w-full gap-x-6 gap-y-8 sm:grid-cols-12 mt-6 mb-1">
				<div className="sm:col-span-12 ml-auto mr-auto">
					<ButtonDefault description={textoCriarAtualizar} onClick={handleOpen}/>
					<Dialog open={openDialog} handler={handleOpen} size={"sm"} animate={BaseHelper.AnimationDialog}>
						<DialogHeader>Tem certeza que deseja {novo ? "criar" : "atualizar"} o termo?</DialogHeader>
						<DialogFooter className={"space-x-2"}>
							<ButtonDefault color={"red"} description={"Cancelar"} onClick={handleOpen} desabilitar={desabilitarBotao}/>
							<ButtonSubmit form={"formTermo"} color={"green"} description={"Confirmar"} desabilitar={desabilitarBotao}/>
						</DialogFooter>
					</Dialog>
				</div>
			</div>
		</form>
	);
};

export default TermoFormulario;