import {Api} from "providers/Api";
import {ApiResult} from "interfaces/ApiResult";
import BuscarComboboxCommand from "interfaces/Commands/ParametrosCommands/BuscarComboboxCommand";
import {IBuscarComboboxResult} from "interfaces/CommandsResults/ParametrosResults/IBuscarComboboxResult";
import {IBuscarCepResult} from "interfaces/CommandsResults/ParametrosResults/IBuscarCepResult";
import {IListarCartoriosResult} from "interfaces/CommandsResults/ParametrosResults/IListarCartoriosResult";
import AtivarDesativarCartorioCommand from "interfaces/Commands/ParametrosCommands/AtivarDesativarCartorioCommand";
import {IAtivarDesativarCartorioResult} from "interfaces/CommandsResults/ParametrosResults/IAtivarDesativarCartorioResult";
import SalvarAtualizarCartorioCommand from "interfaces/Commands/ParametrosCommands/SalvarAtualizarCartorioCommand";
import {ISalvarAtualizarCartorioResult} from "interfaces/CommandsResults/ParametrosResults/ISalvarAtualizarCartorioResult";
import {IBuscarTermosUsoResult} from "interfaces/CommandsResults/ParametrosResults/IBuscarTermosUsoResult";

const controller = "/parametros";

const buscarCep = (cep: string) => Api.get<ApiResult<IBuscarCepResult>>(`${controller}/buscarCep/${cep}`);
const buscarCombobox = (data: BuscarComboboxCommand, token: string) => Api.post<ApiResult<IBuscarComboboxResult[]>>(`${controller}/buscarCombobox`, data, {
	headers: {
		token: token
	}
});
const listarCartorios = (token: string) => Api.post<ApiResult<IListarCartoriosResult[]>>(`${controller}/cartorios/listar`, {}, {
	headers: {
		token: token
	}
});
const ativarDesativarCartorio = (data: AtivarDesativarCartorioCommand, token: string) => Api.post<ApiResult<IAtivarDesativarCartorioResult>>(`${controller}/cartorios/ativarDesativar`, data, {
	headers: {
		token: token
	}
});
const salvarAtualizarCartorio = (data: SalvarAtualizarCartorioCommand, token: string) => Api.post<ApiResult<ISalvarAtualizarCartorioResult>>(`${controller}/cartorios/salvarAtualizar`, data, {
	headers: {
		token: token
	}
});
const buscarTermosUso = (token: string) => Api.post<ApiResult<IBuscarTermosUsoResult>>(`${controller}/termosUso/buscar`, {}, {
	headers: {
		token: token
	}
});

export const ParametrosService = {
	buscarCep,
	buscarCombobox,
	listarCartorios,
	ativarDesativarCartorio,
	salvarAtualizarCartorio,
	buscarTermosUso
};