import {useNavigate, useParams} from "react-router-dom";
import styles from "./Cracha.module.scss";
import {Card, CardBody, Textarea, Typography} from "@material-tailwind/react";
import ToastContainerComponent from "components/ToastContainer";
import React, {useEffect, useState} from "react";
import SpinnerComponent from "components/Spinner";
import {ToastHelper} from "helpers/ToastHelper";
import EStrings from "enums/EStrings";
import ERotas from "enums/ERotas";
import {UsuariosService} from "services/UsuariosService";
import {IPerfilCommand} from "interfaces/Commands/UsuariosCommands/IPerfilCommand";
import {DataHelper} from "helpers/DataHelper";
import {Grid} from "@mui/material";
import Input from "../../../components/Inputs/Default";
import classNames from "classnames";
import Cabecalho from "assets/cabecalho.png";
import ModalImage from "react-modal-image";
import {Cracha, createCrachaDefault} from "../../../interfaces/Classes/Usuarios/Cracha";

const UsuarioCracha = () => {
	const navigate = useNavigate();
	const {token} = useParams();

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [data, setData] = useState<Cracha>(createCrachaDefault({}));

	useEffect(() => {
		buscarPerfil();
	}, [0]);

	const buscarPerfil = async () => {
		setIsLoading(true);

		if (token === undefined) {
			ToastHelper("warning", EStrings.TOKEN_OBRIGATORIO);
			setTimeout(() => {
				return navigate(ERotas.LOGIN);
			}, EStrings.TIMEOUT);

			return;
		}

		try {
			const params: IPerfilCommand = {
				token: token
			};

			const result = await UsuariosService.perfil(params);

			if (!result) {
				setIsLoading(false);
				ToastHelper("warning", EStrings.ERRO_RESULT);
				setTimeout(() => {
					return navigate(ERotas.LOGIN);
				}, EStrings.TIMEOUT);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				ToastHelper("warning", errors[0].message);
				setIsLoading(false);
				setTimeout(() => {
					return navigate(ERotas.LOGIN);
				}, EStrings.TIMEOUT);

				return;
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);
				setIsLoading(false);
				setTimeout(() => {
					return navigate(ERotas.LOGIN);
				}, EStrings.TIMEOUT);

				return;
			}

			const data = body.data;

			setData((prevState) => ({
				...prevState,
				nome: data.nome,
				sobrenome: data.sobrenome,
				documentoIdentidade: data.documento_identidade,
				orgaoExpedidor: data.orgao_expedidor,
				cpf: data.cpf,
				dataCelebrante: data.celebrante_desde,
				situacaoCadastro: data.situacao_cadastro
			}));

			if (data.imagem !== "") {
				setData((prevState) => ({
					...prevState,
					imagem: data.imagem
				}));
			}

			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);

			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			setTimeout(() => {
				return navigate(ERotas.LOGIN);
			}, EStrings.TIMEOUT);

			return;
		}
	};

	const handleClick = (route: string) => {
		switch (route) {
		case "cartorio":
			window.open("https://servicos.cdtsp.com.br/validarregistro/Home/Consulta?v=00231634155132358", "_blank");
			break;
		default:
			navigate("/");
		}
	};

	return (
		<>
			{isLoading &&
				<SpinnerComponent/>
			}

			<section className={styles.container}>
				<Card className={classNames({
					["max-w-[35rem] overflow-hidden"]: true,
					[styles.container__card]: true
				})}>
					<img src={Cabecalho} alt={"Cabecalho"}/>
					<CardBody className={classNames({
						["text-center space-y-2"]: true,
						[styles.container__card__body]: true
					})}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Input
									type={"text"}
									label={"Nome do Celebrante"}
									readonly={true}
									value={`${data.nome} ${data.sobrenome}`}
									validation={false}
								/>
							</Grid>

							<Grid item xs={4}>
								<ModalImage small={data.imagem} large={data.imagem} alt={"Perfil"} hideZoom={false} className={styles.container__imagem__profile}/>
							</Grid>

							<Grid item xs={8}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Input
											type={"text"}
											label={"Doc. Identidade - Órgão Emissor/UF"}
											readonly={true}
											value={`${data.documentoIdentidade} - ${data.orgaoExpedidor}`}
										/>
									</Grid>

									<Grid item xs={12}>
										<Input
											type={"text"}
											label={"CPF"}
											readonly={true}
											value={data.cpf ?? ""}
										/>
									</Grid>

									<Grid item xs={12}>
										<Textarea
											label={"Obs.:"}
											variant={"outlined"}
											size={"md"}
											color={"amber"}
											value={`Cargo Pastor(a) Celebrante\nCredenciado(a) em ${DataHelper.formatDateString(data.dataCelebrante, "DD/MM/YYYY")}`}
											readOnly={true}
										/>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12}>
								<Input
									type={"text"}
									label={"Status de Registro na Instituição"}
									readonly={true}
									value={data.situacaoCadastro ? "ATIVO" : "INATIVO"}
								/>
							</Grid>
						</Grid>
					</CardBody>
				</Card>
				<Typography color={"blue-gray"} className={"font-normal"}>
					&copy;&ensp; {DataHelper.getNow().getFullYear()} ICRD - 8° RTDPJ
				</Typography>
				<Typography
					color={"blue-gray"}
					className={"font-monospace cursor-pointer"}
					style={{
						textDecoration: "underline"
					}}
					onClick={() => handleClick("cartorio")}
				>
					Oficial de Registro de Títulos e Documentos e Civil de Pessoa Jurídica da Capital - SP
				</Typography>
				<ToastContainerComponent/>
			</section>
		</>
	);
};

export default UsuarioCracha;