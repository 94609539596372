import {Outlet, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {LogErrorHelper} from "../helpers/LogErrorHelper";
import EStrings from "../enums/EStrings";
import ERotas from "../enums/ERotas";
import {UsuariosService} from "../services/UsuariosService";
import SpinnerComponent from "../components/Spinner";
import useData from "../contexts/dataProvider/useData";

const UserMiddleware = () => {
	const navigate = useNavigate();
	const {auth, setUser} = useData();

	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		if (!auth?.token) {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			navigate(ERotas.LOGIN);
			return;
		}

		obterDadosUsuario();
	}, [0]);

	const obterDadosUsuario = async () => {
		setLoading(true);

		const token = auth?.token ?? "";

		try {
			const result = await UsuariosService.buscarDados(token);

			if (!result) {
				LogErrorHelper.redirectToLogin("warning", EStrings.ERRO_RESULT);

				return navigate(ERotas.LOGIN);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				LogErrorHelper.redirectToLogin("warning", errors[0].message);

				return navigate(ERotas.LOGIN);
			}

			const body = result.data;

			if (body == null) {
				LogErrorHelper.redirectToLogin("warning", EStrings.ERRO_BUSCAR_DADOS);

				return navigate(ERotas.LOGIN);
			}

			const data = body.data;


			if (data == undefined) {
				LogErrorHelper.redirectToLogin("warning", EStrings.ERRO_BUSCAR_DADOS);

				return navigate(ERotas.LOGIN);
			}

			setUser({
				nome: data.nome,
				sobrenome: data.sobrenome,
				token: data.token,
				acesso: data.acesso,
				ceo: data.ceo,
				foto: data.foto,
				aceito_termo: data.aceito_termo ?? false
			});

			setLoading(false);
		} catch (error) {
			if (error instanceof Error) {
				LogErrorHelper.redirectToLogin("error", error.message);
			} else {
				LogErrorHelper.redirectToLogin("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return navigate(ERotas.LOGIN);
		}
	};

	return (
		<>
			{loading &&
				<SpinnerComponent/>
			}

			<Outlet/>
		</>
	);
};

export default UserMiddleware;