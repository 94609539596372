import {Input} from "@material-tailwind/react";
import {HiOutlineEye, HiOutlineEyeOff} from "react-icons/hi";
import PasswordProps from "./_interfaces/PasswordProps";

const Password = (props: PasswordProps) => {
	const {
		label,
		value,
		visible,
		setVisible,
		maxLength,
		required = false,
		readonly = false,
		onChange,
		onBlur
	} = props;

	const handleChangeIcon = () => {
		setVisible(!visible);
	};

	return (
		<div className="w-full">
			<Input
				crossOrigin={undefined}
				type={visible ? "text" : "password"}
				label={label}
				value={value}
				color={"amber"}
				variant={"outlined"}
				size={"md"}
				maxLength={maxLength}
				onChange={onChange}
				onBlur={onBlur}
				required={required}
				readOnly={readonly}
				icon={visible ?
					<HiOutlineEyeOff onClick={handleChangeIcon} className={"cursor-pointer"}/> :
					<HiOutlineEye onClick={handleChangeIcon} className={"cursor-pointer"}/>
				}
			/>
		</div>
	);
};

export default Password;