import {animate} from "@material-tailwind/react/types/components/chip";
import {v4 as uuidv4} from "uuid";

const FileToBase64 = (file: File): Promise<string> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.readAsDataURL(file);

		reader.onload = () => {
			if (reader.result) {
				resolve(reader.result as string);
			} else {
				reject(new Error("Failed to read the file."));
			}
		};

		reader.onerror = (error) => {
			reject(error);
		};
	});
};

const AnimationAccordion: animate = {
	mount: {scale: 1},
	unmount: {scale: 0.9},
};

const AnimationDialog: animate = {
	mount: {scale: 1, y: 0},
	unmount: {scale: 0.9, y: -100},
};

const AnimationTooltip: animate = {
	mount: {scale: 1, y: 0},
	unmount: {scale: 0, y: 25},
};

const GetToken = () => {
	return uuidv4();
};

const GetSizeFile = (size: number) => {
	return size > 1024
		? size > 1048576
			? Math.round(size / 1048576) + "mb"
			: Math.round(size / 1024) + "kb"
		: size + "b";
};

const isBase64 = (str: string | null | undefined): boolean => {
	if (!str) {
		return false;
	}

	const regex = /^[A-Za-z0-9+/=]+$/;
	return regex.test(str) && str.length % 4 === 0;
};

export const BaseHelper = {
	AnimationAccordion,
	AnimationDialog,
	AnimationTooltip,
	FileToBase64,
	GetToken,
	GetSizeFile,
	isBase64
};