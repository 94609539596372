import {IBuscarComboboxResult} from "../ParametrosResults/IBuscarComboboxResult";

interface NubenteTermoResult {
	nome: string;
	nome_atualizacao: string;
	naturalidade: string;
	nome_pai: string;
	falecimento_pai: string;
	falecimento_pai_object: IBuscarComboboxResult | null;
	data_falecimento_pai: string;
	data_falecimento_pai_date: Date | null;
	endereco_pai: string;
	nome_mae: string;
	falecimento_mae: string;
	falecimento_mae_object: IBuscarComboboxResult | null;
	data_falecimento_mae: string;
	data_falecimento_mae_date: Date | null;
	endereco_mae: string;
	token: string;
}

const createNubenteTermoDefault = (overrides: Partial<NubenteTermoResult>): NubenteTermoResult => ({
	nome: "",
	nome_atualizacao: "",
	naturalidade: "",
	nome_pai: "",
	falecimento_pai: "",
	falecimento_pai_object: null,
	data_falecimento_pai: "",
	data_falecimento_pai_date: null,
	endereco_pai: "",
	nome_mae: "",
	falecimento_mae: "",
	falecimento_mae_object: null,
	data_falecimento_mae: "",
	data_falecimento_mae_date: null,
	endereco_mae: "",
	token: "",
	...overrides
});

export {createNubenteTermoDefault};
export type {NubenteTermoResult};