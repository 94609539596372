const get = (storage: Storage, property: string, key?: string): any => {
	const item = storage.getItem(property);
	if (!item) {
		return null;
	}

	const isBase64 = (str: string): boolean => {
		try {
			return btoa(atob(str)) === str;
		} catch (err) {
			return false;
		}
	};

	// SE O ITEM FOR STRING E NÃO FOR BASE64, RETORNE.
	if (!isBase64(item)) {
		// Tentar converter para boolean ou number se possível
		if (item === "true" || item === "false") {
			return item === "true";
		}
		if (!isNaN(Number(item))) {
			return Number(item);
		}
		return item;
	}

	try {
		// SE FOR STRING E FOR BASE64, DESERIALIZE
		const decodedItem = atob(item);
		const parsedItem = JSON.parse(decodedItem);

		// SE FOR STRING, BOOLEAN OU NÚMERO, RETORNE.
		if (typeof parsedItem === "string" || typeof parsedItem === "boolean" || typeof parsedItem === "number") {
			return parsedItem;
		}

		// SE FOR OBJETO, PEGUE O VALOR DE ACORDO COM A VARIÁVEL KEY E RETORNE.
		if (typeof parsedItem === "object" && parsedItem !== null) {
			if (key) {
				return parsedItem[key] ?? null;
			}

			// SE FOR OBJETO E NÃO TIVER CHAVE, DEVOLVER O OBJETO INTEIRO.
			return parsedItem;
		}
	} catch (error) {
		console.error("Erro ao decodificar/deserializar o item:", error);
		return null;
	}

	return null;
};

const StorageHelper = {
	get
};

export default StorageHelper;