import styles from "./EsqueciSenha.module.scss";
import {FormEvent, useState} from "react";
import Input from "components/Inputs/Default";
import ButtonSubmit from "components/Buttons/Submit";
import {Button} from "@material-tailwind/react";
import {useNavigate} from "react-router-dom";
import {IRecuperarSenhaCommand} from "interfaces/Commands/UsuariosCommands/IRecuperarSenhaCommand";
import {ToastHelper} from "helpers/ToastHelper";
import EStrings from "enums/EStrings";
import {UsuariosService} from "services/UsuariosService";
import {ISolicitarRecuperarSenhaCommand} from "interfaces/Commands/UsuariosCommands/ISolicitarRecuperarSenhaCommand";
import ERotas from "enums/ERotas";

const EsqueciSenha = () => {
	const navigate = useNavigate();
	const [email, setEmail] = useState<string>("");
	const [senha, setSenha] = useState<string>("");
	const [token, setToken] = useState<string>("");
	const [isRecuperaSenha, setIsRecuperaSenha] = useState<boolean>(false);
	const [isValidToken, setIsValidToken] = useState<boolean | undefined>(undefined);
	const [desabilitarBotao, setDesabilitarBotao] = useState<boolean>(false);

	const handleAcessarPagina = (rota: string) => {
		navigate(rota);
	};

	const handleTokenChange = (value: string) => {
		setToken(value);
		setIsValidToken(value !== "" && value !== undefined);
	};

	const enviarFormulario = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setDesabilitarBotao(true);

		if (isRecuperaSenha) {
			await validarTokenRecuperacao();
		} else {
			await solicitarToken();
		}
	};

	const solicitarToken = async () => {
		const params: ISolicitarRecuperarSenhaCommand = {
			email: email
		};

		if (params.email == null || params.email == "") {
			setDesabilitarBotao(false);
			ToastHelper("warning", EStrings.EMAIL_OBRIGATORIO);

			return;
		}

		try {
			const result = await UsuariosService.solicitarRecuperarSenha(params);

			if (result == null) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.USUARIO_NAO_LOCALIZADO);

				return;
			}

			const body = result.data;

			if (body == null) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return;
			}

			const data = body.data;

			if (!data.sucesso) {
				ToastHelper("warning", EStrings.ERRO_SOLICITAR_RECUPERAR_SENHA);

				return;
			}

			ToastHelper("success", EStrings.SUCESSO_SOLICITAR_RECUPERAR_SENHA);

			setIsRecuperaSenha(true);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}
		} finally {
			setDesabilitarBotao(false);
		}
	};

	const validarTokenRecuperacao = async () => {
		try {
			const params: IRecuperarSenhaCommand = {
				email: email,
				token: token,
				senha: senha
			};

			if (params.email == null || params.email == "") {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.EMAIL_OBRIGATORIO);

				return;
			}

			if (params.token == null || params.token == "") {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.TOKEN_OBRIGATORIO);

				return;
			}

			if (params.senha == null || params.senha == "") {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.SENHA_OBRIGATORIO);

				return;
			}

			const result = await UsuariosService.recuperarSenha(params);

			if (result == null) {
				ToastHelper("warning", EStrings.USUARIO_NAO_LOCALIZADO);

				return;
			}

			const body = result.data;

			if (body == null) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return;
			}

			const data = body.data;

			if (!data.sucesso) {
				ToastHelper("warning", EStrings.ERRO_RECUPERAR_SENHA);

				return;
			}

			ToastHelper("success", EStrings.SUCESSO_RECUPERAR_SENHA);

			navigate(ERotas.LOGIN);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}
		} finally {
			setDesabilitarBotao(false);
		}
	};

	return (
		<>
			<form className={styles.form} id={"formEsqueciSenha"} onSubmit={enviarFormulario} noValidate>
				<div className={"grid w-full gap-x-6 gap-y-8 sm:grid-cols-12 mt-4 mb-7"}>
					<div className={"sm:col-span-12"}>
						<Input
							type={"email"}
							label={"E-mail"}
							value={email}
							onChange={e => setEmail(e.currentTarget.value)}
							required={true}
						/>
					</div>

					{isRecuperaSenha &&
						<>
							<div className={"sm:col-span-12"}>
								<Input
									type={"text"}
									label={"Token"}
									onChange={event => handleTokenChange(event.currentTarget.value)}
									validation={isValidToken != undefined ? isValidToken : false}
									required={true}
								/>
							</div>

							<div className={"sm:col-span-12"}>
								<Input
									type={"password"}
									label={"Senha"}
									onChange={e => setSenha(e.currentTarget.value)}
									required={true}
								/>
							</div>
						</>
					}

					<div className={"sm:col-span-12"}>
						<ButtonSubmit fullWidth={true} form={"formEsqueciSenha"} desabilitar={desabilitarBotao} description={"Criar nova senha"}/>
					</div>
				</div>
				<hr className={"mt-4 mb-1"}/>
			</form>

			<Button size={"sm"} variant={"text"} color={"amber"} onClick={() => handleAcessarPagina(ERotas.LOGIN)}>Login</Button>
		</>
	);
};

export default EsqueciSenha;