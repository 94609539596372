import {Dialog, DialogBody, DialogFooter, DialogHeader} from "@material-tailwind/react";
import ButtonSubmit from "components/Buttons/Submit";
import {Dispatch, FormEvent, SetStateAction, useEffect, useState} from "react";
import {IListarCartoriosResult} from "interfaces/CommandsResults/ParametrosResults/IListarCartoriosResult";
import Input from "components/Inputs/Default";
import ButtonDefault from "components/Buttons/Default";
import {useNavigate} from "react-router-dom";
import {ToastHelper} from "helpers/ToastHelper";
import EStrings from "enums/EStrings";
import {ParametrosService} from "services/ParametrosService";
import SalvarAtualizarCartorioCommand from "interfaces/Commands/ParametrosCommands/SalvarAtualizarCartorioCommand";
import ERotas from "enums/ERotas";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import {BaseHelper} from "helpers/BaseHelper";
import useData from "../../../../contexts/dataProvider/useData";

interface IProps {
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	titulo: string;
	data: IListarCartoriosResult | null;
	setAtualizarLista: Dispatch<SetStateAction<boolean>>;
}

const CartorioFormulario = (props: IProps) => {
	const navigate = useNavigate();
	const {auth} = useData();
	const token = auth?.token;
	const {open, setOpen, titulo, data, setAtualizarLista} = props;
	const [desabilitarBotao, setDesabilitarBotao] = useState<boolean>(false);

	const [descricaoCartorio, setDescricaoCartorio] = useState<string>("");

	useEffect(() => {
		setDescricaoCartorio(data?.descricao ?? "");
	}, [data]);

	const enviarFormulario = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setDesabilitarBotao(true);

		if (!token || token === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			return navigate(ERotas.LOGIN);
		}

		try {
			const params: SalvarAtualizarCartorioCommand = {
				token: data?.token ?? "",
				descricao: descricaoCartorio,
				valor: descricaoCartorio
			};

			const result = await ParametrosService.salvarAtualizarCartorio(params, token);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);
				setDesabilitarBotao(false);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					setDesabilitarBotao(false);

					return navigate(ERotas.LOGIN);
				}

				ToastHelper("warning", errors[0].message);
				setDesabilitarBotao(false);

				return;
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				setDesabilitarBotao(false);
				return;
			}

			setDesabilitarBotao(false);
			setOpen(false);

			ToastHelper("success", `Cartório ${params.token == "" ? "criado" : "atualizado"} com sucesso!`);

			setAtualizarLista(true);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return navigate(ERotas.PRINCIPAL);
		}
	};

	return (
		<Dialog open={open} handler={() => setOpen(!open)} size={"sm"} animate={BaseHelper.AnimationDialog}>
			<DialogHeader>{titulo}</DialogHeader>
			<DialogBody>
				<form id={"formCartorio"} onSubmit={enviarFormulario} noValidate>
					<Input type={"text"} label={"Nome do Cartório"} value={descricaoCartorio} onChange={(e) => setDescricaoCartorio(e.target.value)}/>
				</form>
			</DialogBody>
			<DialogFooter className={"space-x-2"}>
				<ButtonDefault color={"red"} description={"Cancelar"} onClick={() => setOpen(!open)} desabilitar={desabilitarBotao}/>
				<ButtonSubmit form={"formCartorio"} color={"green"} description={"Confirmar"} desabilitar={desabilitarBotao}/>
			</DialogFooter>
		</Dialog>
	);
};

export default CartorioFormulario;