import {useEffect, useState} from "react";
import {UserResult, deserializeUser} from "../../interfaces/CommandsResults/UsuariosResults/UserResult";
import DataContext from "./DataContext";
import DataProviderProps from "./_interfaces/DataProviderProps";
import {AuthResult, deserializeAuth} from "../../interfaces/CommandsResults/UsuariosResults/AuthResult";

const DataProvider = (props: DataProviderProps) => {
	const {
		children
	} = props;

	const [user, setUser] = useState<UserResult | null>(() => {
		return deserializeUser();
	});

	const [auth, setAuth] = useState<AuthResult | null>(() => {
		return deserializeAuth();
	});

	useEffect(() => {
		if (user == null) {
			localStorage.removeItem("user");
			return;
		}

		const updated: UserResult = Object.freeze(user);

		localStorage.setItem("user", btoa(JSON.stringify(updated)));
	}, [user]);

	useEffect(() => {
		if (auth == null) {
			localStorage.removeItem("auth");
			return;
		}

		const updated: AuthResult = Object.freeze(auth);

		localStorage.setItem("auth", btoa(JSON.stringify(updated)));
	}, [auth]);

	const clearData = () => {
		setUser(null);
		setAuth(null);
	};

	return (
		<DataContext.Provider
			value={{
				clearData,
				user,
				setUser,
				auth,
				setAuth
			}}
		>
			{children}
		</DataContext.Provider>
	);
};

export default DataProvider;