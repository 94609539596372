import {Button} from "@material-tailwind/react";
import ButtonProps from "./_interfaces/ButtonProps";

const ButtonDefault = (props: ButtonProps) => {
	const {
		description = "",
		variant = "gradient",
		color = "amber",
		onClick,
		desabilitar = false
	} = props;

	return (
		<Button
			className="rounded-full"
			size="sm"
			variant={variant}
			color={color}
			onClick={onClick}
			disabled={desabilitar}
		>
			{description}
		</Button>
	);
};

export default ButtonDefault;