import LogoUser from "../../../assets/user-img.png";

interface Cracha {
	imagem: string;
	nome: string | null;
	sobrenome: string | null;
	documentoIdentidade: string | null;
	orgaoExpedidor: string | null;
	cpf: string | null;
	dataCelebrante: string | null;
	situacaoCadastro: boolean;
}

const createCrachaDefault = (overrides: Partial<Cracha>): Cracha => ({
	imagem: LogoUser,
	nome: null,
	sobrenome: null,
	documentoIdentidade: null,
	orgaoExpedidor: null,
	cpf: null,
	dataCelebrante: null,
	situacaoCadastro: false,
	...overrides
});

export {createCrachaDefault};
export type {Cracha};