interface ILoginCommand {
	email: string;
	senha: string;
	conectado: boolean;
}

const createLoginDefault = (overrides: Partial<ILoginCommand>): ILoginCommand => ({
	email: "",
	senha: "",
	conectado: false,
	...overrides
});

export {createLoginDefault};
export type {ILoginCommand};