import ReactECharts from "echarts-for-react";
import React, {useEffect, useState} from "react";
import LineChartProps from "./_interfaces/LineChartProps";
import ChartProps from "./_interfaces/ChartProps";

const LineChart = (props: LineChartProps) => {
	const {
		titulo,
		data
	} = props;

	const [series, setSeries] = useState<ChartProps[]>([]);

	useEffect(() => {
		if (data !== null && data !== undefined) {
			const series: ChartProps[] = data.data.map((item): ChartProps => ({
				name: item.titulo,
				type: "line",
				data: item.data,
				smooth: true
			}));

			setSeries(series);
		}
	}, [data]);

	const option = {
		title: {
			text: titulo,
			left: "center"
		},
		tooltip: {
			trigger: "axis"
		},
		legend: {
			bottom: 10
		},
		toolbox: {
			show: true,
			feature: {
				magicType: {type: ["line", "bar"]}
			}
		},
		xAxis: {
			type: "category",
			boundaryGap: false,
			data: data?.categoria.data
		},
		yAxis: {
			type: "value",
			axisLabel: {
				formatter: "{value}"
			}
		},
		series: series
	};

	return <ReactECharts option={option}/>;
};

export default LineChart;