import {IListarRequerimentoResult} from "interfaces/CommandsResults/RequerimentosResults/IListarRequerimentoResult";
import React, {Dispatch, SetStateAction, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Dialog, DialogBody, DialogFooter, DialogHeader, Typography} from "@material-tailwind/react";
import ButtonDefault from "components/Buttons/Default";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import EStrings from "enums/EStrings";
import ERotas from "enums/ERotas";
import {ToastHelper} from "helpers/ToastHelper";
import IReprovarCancelamentoRequerimentoCommand from "interfaces/Commands/RequerimentosCommands/IReprovarCancelamentoRequerimentoCommand";
import {RequerimentosService} from "services/RequerimentosService";
import IAprovarCancelamentoRequerimentoCommand from "interfaces/Commands/RequerimentosCommands/IAprovarCancelamentoRequerimentoCommand";
import {BaseHelper} from "helpers/BaseHelper";
import useData from "../../../../../contexts/dataProvider/useData";

interface IProps {
	requerimento?: IListarRequerimentoResult;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setAtualizarLista: Dispatch<SetStateAction<boolean>>;
}

const RequerimentoModalVisualizarCancelamento = (props: IProps) => {
	const {
		requerimento,
		open,
		setOpen,
		setAtualizarLista
	} = props;

	const {auth} = useData();
	const navigate = useNavigate();
	const tokenVisao = auth?.token;

	const [desabilitarBotao, setDesabilitarBotao] = useState<boolean>(false);

	const handleReprovarCancelamento = async () => {
		setDesabilitarBotao(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			navigate(ERotas.LOGIN);
			return;
		}

		try {
			const params: IReprovarCancelamentoRequerimentoCommand = {
				token: requerimento?.token ?? ""
			};

			const result = await RequerimentosService.reprovarCancelamento(params, tokenVisao);

			if (!result) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				errors.forEach((item) => {
					ToastHelper("warning", item.message);
				});

				setDesabilitarBotao(false);
				return;
			}

			const body = result.data;

			if (!body) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return;
			}

			setDesabilitarBotao(false);
			setOpen(false);

			ToastHelper("success", "Cancelamento reprovado com sucesso.");

			setAtualizarLista(true);
		} catch (error) {
			setDesabilitarBotao(false);

			if (error instanceof Error) {
				ToastHelper("error", error.message);
				setOpen(false);

				return;
			}

			ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			setOpen(false);

			return;
		}
	};

	const handleAprovarCancelamento = async () => {
		setDesabilitarBotao(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			navigate(ERotas.LOGIN);
			return;
		}

		try {
			const params: IAprovarCancelamentoRequerimentoCommand = {
				token: requerimento?.token ?? ""
			};

			const result = await RequerimentosService.aprovarCancelamento(params, tokenVisao);

			if (!result) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				errors.forEach((item) => {
					ToastHelper("warning", item.message);
				});

				setDesabilitarBotao(false);
				return;
			}

			const body = result.data;

			if (!body) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return;
			}

			setDesabilitarBotao(false);
			setOpen(false);

			ToastHelper("success", "Cancelamento aprovado com sucesso.");

			setAtualizarLista(true);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
				setOpen(false);

				return;
			}

			ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			setOpen(false);

			return;
		}
	};

	return (
		<Dialog open={open} handler={() => setOpen(!open)} size={"sm"} animate={BaseHelper.AnimationDialog}>
			<DialogHeader>Motivo de Cancelamento # {requerimento?.id}</DialogHeader>
			<DialogBody>
				<Typography variant={"small"} className={"font-monospace text-justify"}>
					{requerimento?.motivo_cancelamento ?? ""}
				</Typography>
				&emsp;
				<Typography variant={"small"} className={"font-monospace font-bold text-justify"} color={"red"}>
					OBS.: ESTA AÇÃO É IRREVERSÍVEL.
				</Typography>
			</DialogBody>
			<DialogFooter className={"space-x-2"}>
				<ButtonDefault color={"red"} description={"Reprovar"} onClick={handleReprovarCancelamento} desabilitar={desabilitarBotao}/>
				<ButtonDefault color={"green"} description={"Aprovar"} onClick={handleAprovarCancelamento} desabilitar={desabilitarBotao}/>
			</DialogFooter>
		</Dialog>
	);
};

export default RequerimentoModalVisualizarCancelamento;