import {Dialog, DialogBody, DialogFooter, DialogHeader, Textarea, Typography} from "@material-tailwind/react";
import {IListarRequerimentoResult} from "interfaces/CommandsResults/RequerimentosResults/IListarRequerimentoResult";
import React, {Dispatch, FormEvent, SetStateAction, useState} from "react";
import ButtonDefault from "components/Buttons/Default";
import ERotas from "enums/ERotas";
import {useNavigate} from "react-router-dom";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import EStrings from "enums/EStrings";
import {ToastHelper} from "helpers/ToastHelper";
import ISolicitarCancelamentoRequerimentoCommand from "interfaces/Commands/RequerimentosCommands/ISolicitarCancelamentoRequerimentoCommand";
import ButtonSubmit from "components/Buttons/Submit";
import {RequerimentosService} from "services/RequerimentosService";
import {BaseHelper} from "helpers/BaseHelper";
import useData from "../../../../../contexts/dataProvider/useData";

interface IProps {
	requerimento?: IListarRequerimentoResult;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setAtualizarLista: Dispatch<SetStateAction<boolean>>;
}

const RequerimentoModalSolicitarCancelamento = (props: IProps) => {
	const {
		requerimento,
		open,
		setOpen,
		setAtualizarLista
	} = props;

	const {auth} = useData();
	const navigate = useNavigate();
	const tokenVisao = auth?.token;

	const [motivoCancelamento, setMotivoCancelamento] = useState<string>("");
	const [desabilitarBotao, setDesabilitarBotao] = useState<boolean>(false);

	const handleEnviarFormularioMotivoCancelamento = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setDesabilitarBotao(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			navigate(ERotas.LOGIN);
			return;
		}

		try {
			const params: ISolicitarCancelamentoRequerimentoCommand = {
				token: requerimento?.token ?? "",
				motivo_cancelamento: motivoCancelamento
			};

			const result = await RequerimentosService.solicitarCancelamento(params, tokenVisao);

			if (!result) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					return;
				}

				ToastHelper("warning", errors[0].message);
				setDesabilitarBotao(false);

				return;
			}

			const body = result.data;

			if (!body) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return;
			}

			setDesabilitarBotao(false);
			setOpen(false);

			ToastHelper("success", "Solicitação de Cancelamento realizada com sucesso.");

			setAtualizarLista(true);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
				setOpen(false);

				return;
			}

			ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			setOpen(false);

			return;
		}
	};

	return (
		<Dialog open={open} handler={() => setOpen(!open)} size={"sm"} animate={BaseHelper.AnimationDialog}>
			<DialogHeader>Solicitar Cancelamento # {requerimento?.id}</DialogHeader>
			<DialogBody>
				<Typography variant={"small"} className={"font-monospace text-justify"}>
					Digite abaixo o motivo de cancelamento.
				</Typography>
				&emsp;
				<form id={"formSolicitacaoCancelamento"} onSubmit={handleEnviarFormularioMotivoCancelamento} noValidate>
					<Textarea
						color={"blue"}
						label="Motivo do Cancelamento"
						value={motivoCancelamento}
						variant="outlined"
						size={"md"}
						required={true}
						onChange={(e) => setMotivoCancelamento(e.target.value)}
					/>
				</form>
			</DialogBody>
			<DialogFooter className={"space-x-2"}>
				<ButtonDefault color={"red"} description={"Fechar"} onClick={() => setOpen(false)}/>
				<ButtonSubmit form={"formSolicitacaoCancelamento"} description={"Solicitar Cancelamento"} color={"green"} desabilitar={desabilitarBotao}/>
			</DialogFooter>
		</Dialog>
	);
};

export default RequerimentoModalSolicitarCancelamento;