import {Spinner as Spin} from "@material-tailwind/react";
import styles from "./Spinner.module.scss";

const Spinner = () => {
	return (
		<div className={styles.loading}>
			<Spin color={"amber"} className="h-12 w-12"/>
		</div>
	);
};

export default Spinner;