import {useNavigate} from "react-router-dom";
import {FormEvent, useState} from "react";
import styles from "./PrimeiroAcesso.module.scss";
import Input from "components/Inputs/Default";
import ButtonDefault from "components/Buttons/Default";
import ButtonSubmit from "components/Buttons/Submit";
import {ToastHelper} from "helpers/ToastHelper";
import {ValidarEmailHelper} from "helpers/ValidarEmailHelper";
import {UsuariosService} from "services/UsuariosService";
import ERotas from "enums/ERotas";
import EStrings from "enums/EStrings";
import {IPrimeiroAcessoCommand} from "interfaces/Commands/UsuariosCommands/IPrimeiroAcessoCommand";
import {IAtualizarSenhaCommand} from "../../../interfaces/Commands/UsuariosCommands/IAtualizarSenhaCommand";

const UsuarioPrimeiroAcesso = () => {
	const navigate = useNavigate();

	const [email, setEmailState] = useState<string>("");
	const [token, setToken] = useState<string>("");
	const [senha, setSenha] = useState<string>("");
	const [isValidEmail, setIsValidEmail] = useState<boolean | undefined>(undefined);
	const [isValidToken, setIsValidToken] = useState<boolean | undefined>(undefined);
	const [isValidSenha, setIsValidSenha] = useState<boolean | undefined>(undefined);
	const [isCriarSenha, setIsCriarSenha] = useState<boolean>(false);

	const [desabilitarBotao, setDesabilitarBotao] = useState<boolean>(true);

	function handleAcessarPagina(rota: string) {
		navigate(rota);
	}

	function handleEmailChange(value: string) {
		setEmailState(value);
		setIsValidEmail(ValidarEmailHelper(value));

		setDesabilitarBotao(!isValidEmail);
	}

	function handleTokenChange(value: string) {
		setToken(value);
		setIsValidToken(value !== "" && value !== undefined);
	}

	function handleSenhaChange(value: string) {
		setSenha(value);
		setIsValidSenha(value !== "" && value !== undefined);
	}

	async function enviarFormulario(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();

		setDesabilitarBotao(true);

		if (!isCriarSenha) {
			await validarTokenPrimeiroAcesso();
		} else {
			await atualizarPrimeiroAcesso();
		}
	}

	async function validarTokenPrimeiroAcesso() {
		const params: IPrimeiroAcessoCommand = {
			email: email,
			token: token
		};

		if (params.email == null || params.email == "") {
			setDesabilitarBotao(false);
			ToastHelper("warning", EStrings.EMAIL_OBRIGATORIO);

			return;
		}

		if (params.token == null || params.token == "") {
			setDesabilitarBotao(false);
			ToastHelper("warning", EStrings.TOKEN_OBRIGATORIO);

			return;
		}

		await UsuariosService.primeiroAcesso(params)
			.then(result => {
				if (result == null) {
					setDesabilitarBotao(false);
					ToastHelper("warning", EStrings.USUARIO_NAO_LOCALIZADO);

					return;
				}

				const errors = result.data.errors;

				if (errors.length > 0) {
					setDesabilitarBotao(false);

					errors.map((item) => {
						ToastHelper("warning", item.message);
					});

					return;
				}

				const body = result.data;

				if (body == null) {
					setDesabilitarBotao(false);
					ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

					return;
				}

				const data = body.data;

				if (!data.sucesso) {
					setDesabilitarBotao(false);
					ToastHelper("warning", EStrings.ERRO_PRIMEIRO_ACESSO);

					return;
				}

				setDesabilitarBotao(false);
				setIsCriarSenha(true);
			})
			.catch(error => {
				setDesabilitarBotao(false);
				ToastHelper("error", error.message);
			});
	}

	async function atualizarPrimeiroAcesso() {
		const params: IAtualizarSenhaCommand = {
			email: email,
			token: token,
			senha: senha
		};

		if (params.email == null || params.email == "") {
			setDesabilitarBotao(false);
			ToastHelper("warning", EStrings.EMAIL_OBRIGATORIO);

			return;
		}

		if (params.token == null || params.token == "") {
			setDesabilitarBotao(false);
			ToastHelper("warning", EStrings.TOKEN_OBRIGATORIO);

			return;
		}

		if (params.senha == null || params.senha == "") {
			setDesabilitarBotao(false);
			ToastHelper("warning", EStrings.SENHA_OBRIGATORIO);

			return;
		}

		await UsuariosService.atualizarSenha(params)
			.then(result => {
				if (result == null) {
					setDesabilitarBotao(false);
					ToastHelper("warning", EStrings.USUARIO_NAO_LOCALIZADO);

					return;
				}

				const errors = result.data.errors;

				if (errors.length > 0) {
					setDesabilitarBotao(false);

					errors.map((item) => {
						ToastHelper("warning", item.message);
					});

					return;
				}

				const body = result.data;

				if (body == null) {
					setDesabilitarBotao(false);
					ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

					return;
				}

				const data = body.data;

				if (!data.sucesso) {
					setDesabilitarBotao(false);
					ToastHelper("warning", EStrings.ERRO_PRIMEIRO_ACESSO);

					return;
				}

				ToastHelper("success", EStrings.SUCESSO_RECUPERAR_SENHA);

				navigate(ERotas.LOGIN);
			})
			.catch(error => {
				setDesabilitarBotao(false);
				ToastHelper("error", error.message);
			});
	}

	return (
		<>
			<form className={styles.form} id={"formPrimeiroAcesso"} onSubmit={enviarFormulario} noValidate>
				<div className={"grid w-full gap-x-6 gap-y-8 sm:grid-cols-12 mt-4 mb-7"}>
					<div className={"sm:col-span-12"}>
						<Input
							type={"email"}
							label={"E-mail"}
							onChange={e => handleEmailChange(e.target.value)}
							validation={isValidEmail != undefined ? isValidEmail : false}
							required={true}
						/>
					</div>

					<div className={"sm:col-span-12"}>
						<Input
							type={"text"}
							label={"Token"}
							onChange={e => handleTokenChange(e.currentTarget.value)}
							validation={isValidToken != undefined ? isValidToken : false}
							required={true}/>
					</div>

					{isCriarSenha &&
						<div className={"sm:col-span-12"}>
							<Input
								type={"password"}
								label={"Senha"}
								onChange={e => handleSenhaChange(e.currentTarget.value)}
								validation={isValidSenha != undefined ? isValidSenha : false}
								required={true}/>
						</div>
					}

					<div className={"sm:col-span-12"}>
						<ButtonSubmit fullWidth={true} form={"formPrimeiroAcesso"} desabilitar={desabilitarBotao} description={"Verificar"}/>
					</div>
				</div>

				<hr className={"mt-4 mb-1"}/>
			</form>

			<ButtonDefault variant={"text"} description={"Login"} onClick={() => handleAcessarPagina(ERotas.LOGIN)}/>
		</>
	);
};

export default UsuarioPrimeiroAcesso;