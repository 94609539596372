import Input from "components/Inputs/Default";
import {DatePicker} from "@mui/x-date-pickers";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {CepHelper} from "helpers/CepHelper";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {IBuscarComboboxResult} from "interfaces/CommandsResults/ParametrosResults/IBuscarComboboxResult";
import {CpfHelper} from "helpers/CpfHelper";
import {ToastHelper} from "helpers/ToastHelper";
import {NubenteCommand} from "../../_interfaces/NubenteCommand";

interface IProps {
	setIsLoading: Dispatch<SetStateAction<boolean>>;
	title: string;
	nubente: NubenteCommand;
	setNubente: Dispatch<SetStateAction<NubenteCommand>>;
	listaEstadosCivis: IBuscarComboboxResult[];
	listaGeneros: IBuscarComboboxResult[];
	listaEstados: IBuscarComboboxResult[];
	listaTiposIdentidades: IBuscarComboboxResult[];
}

const Nubente = (props: IProps) => {
	const {
		setIsLoading,
		title,
		nubente,
		setNubente,
		listaEstadosCivis,
		listaGeneros,
		listaEstados,
		listaTiposIdentidades
	} = props;

	const [ocultarCni, setOcultarCni] = useState<boolean>(false);
	const [ocultarCpfRg, setOcultarCpfRg] = useState<boolean>(false);

	useEffect(() => {
		if (nubente.tipoIdentidadeObject !== null) {
			if (nubente.tipoIdentidadeObject.descricao.includes("CIN")) {
				setOcultarCni(false);
				setOcultarCpfRg(true);
				handleInputChange("cpf", "");
				handleInputChange("documentoIdentidade", "");
				handleInputChange("orgaoExpedidor", "");
			} else if (nubente.tipoIdentidadeObject.descricao.includes("CPF")) {
				setOcultarCni(true);
				setOcultarCpfRg(false);
				handleInputChange("cin", "");
			}
		}
	}, [nubente.tipoIdentidadeObject]);

	useEffect(() => {
		const selectedOption = listaEstadosCivis.find((option) => option.sigla === nubente.estadoCivil);

		handleInputChange("estadoCivilObject", selectedOption ?? null);
	}, [nubente.estadoCivil, listaEstadosCivis]);

	useEffect(() => {
		const selectedOption = listaGeneros.find((option) => option.sigla === nubente.genero);

		handleInputChange("generoObject", selectedOption ?? null);
	}, [nubente.genero, listaGeneros]);

	useEffect(() => {
		const selectedOption = listaEstados.find((option) => option.sigla === nubente.uf);

		handleInputChange("ufObject", selectedOption ?? null);
	}, [nubente.uf, listaEstados]);

	useEffect(() => {
		const selectedOption = listaTiposIdentidades.find((option) => option.sigla === nubente.tipoIdentidade);

		handleInputChange("tipoIdentidadeObject", selectedOption ?? null);
	}, [nubente.tipoIdentidade, listaTiposIdentidades]);

	const handleInputChange = (name: string, value: string | IBuscarComboboxResult | Date | null) => {
		setNubente((prevState) => ({
			...prevState,
			[name]: value
		}));
	};

	const handleCniChange = (value: string) => {
		const formattedCpf = CpfHelper.formatarCpf(value);

		handleInputChange("cin", formattedCpf);

		if (!CpfHelper.validarCpf(formattedCpf)) {
			ToastHelper("warning", "CIN Inválido.");
			handleInputChange("cin", "");
		}
	};

	const handleCpfChange = (value: string) => {
		const formattedCpf = CpfHelper.formatarCpf(value);

		handleInputChange("cpf", formattedCpf);

		if (!CpfHelper.validarCpf(formattedCpf)) {
			ToastHelper("warning", "CPF Inválido.");
			handleInputChange("cpf", "");
		}
	};

	const handleOrgaoExpedidorChange = (value: string) => {
		const orgao = value.trim();

		const regex = /^[^\d]+$/u; // Exclui números usando ^\d (qualquer dígito)

		if (!orgao.match(regex)) {
			ToastHelper("warning", "Não é permitido números no Órgão Expedidor.");

			handleInputChange("orgaoExpedidor", "");
			return;
		}

		handleInputChange("orgaoExpedidor", value);
	};

	const handleProfissaoChange = (value: string) => {
		const profissao = value.trim().toUpperCase();

		const regex = /^[A-ZÀ-Úa-zà-ú\s-]+$/;

		if (!profissao.match(regex)) {
			ToastHelper("warning", "Profissão contém caracteres especiais.");

			handleInputChange("profissao", "");
		}

		if (profissao.includes("AUTONOMO") || profissao.includes("AUTÔNOMO") || profissao.includes("AUTONOMA") || profissao.includes("AUTÔNOMA")) {
			ToastHelper("warning", "Profissão inválida.");

			handleInputChange("profissao", "");
		}
	};

	const handleCepChange = async (value: string) => {
		setIsLoading(true);
		value = CepHelper.formatarCep(value);

		handleInputChange("cep", value);

		const result = await CepHelper.buscarCep(value);

		const setValuesEmpty = () => {
			handleInputChange("endereco", "");
			handleInputChange("bairro", "");
			handleInputChange("cidade", "");
			handleInputChange("uf", "");
		};

		if (result == null) {
			setValuesEmpty();
			setIsLoading(false);

			return;
		}

		handleInputChange("endereco", result.logradouro);
		handleInputChange("bairro", result.bairro);
		handleInputChange("cidade", result.localidade);
		handleInputChange("uf", result.uf);

		setIsLoading(false);
	};

	return (
		<div className={"space-y-4 mb-3"}>
			<legend className={"pb-3"}>{title}</legend>
			<div className={"grid gap-x-6 gap-y-8 sm:grid-cols-12"}>
				<div className={"sm:col-span-9"}>
					<Input
						type={"text"}
						label={"Nome do Nubente"}
						value={nubente.nome ?? undefined}
						validation={nubente.nome != ""}
						required={true}
						onChange={(e) => handleInputChange("nome", e.target.value)}
					/>
				</div>

				<div className={"sm:col-span-3"}>
					<DatePicker
						label="Data de Nascimento"
						format={"dd/MM/yyyy"}
						value={nubente.dataNascimento}
						onChange={(e) => handleInputChange("dataNascimento", e)}
						maxDate={new Date()}
						yearsPerRow={3}
						disableFuture={true}
						slotProps={{
							textField: {
								fullWidth: true,
								required: true,
								size: "small",
								color: "warning"
							}
						}}
					/>
				</div>

				<div className={"sm:col-span-4"}>
					<Input
						type={"text"}
						label={"Nacionalidade"}
						value={nubente.nacionalidade ?? undefined}
						validation={nubente.nacionalidade != null && nubente.nacionalidade != ""}
						required={true}
						onChange={(e) => handleInputChange("nacionalidade", e.target.value)}
					/>
				</div>

				<div className={"sm:col-span-4"}>
					<Autocomplete
						options={listaEstadosCivis}
						getOptionLabel={(option) => option.descricao}
						value={nubente.estadoCivilObject}
						size={"small"}
						renderInput={(params) => <TextField {...params} label="Estado Civil" required={true}/>}
						onChange={(_, newValue) => handleInputChange("estadoCivilObject", newValue)}
					/>
				</div>

				<div className={"sm:col-span-4"}>
					<Autocomplete
						options={listaGeneros}
						getOptionLabel={(option) => option.descricao}
						value={nubente.generoObject}
						size={"small"}
						renderInput={(params) => <TextField {...params} label="Gêneros" required={true}/>}
						onChange={(_, newValue) => handleInputChange("generoObject", newValue)}
					/>
				</div>

				<div className={"sm:col-span-3"}>
					<Autocomplete
						options={listaTiposIdentidades}
						getOptionLabel={(option) => option.descricao}
						value={nubente.tipoIdentidadeObject}
						size={"small"}
						renderInput={(params) => <TextField {...params} label={"Tipo de Identidade"} required={true}/>}
						onChange={(_, newValue) => handleInputChange("tipoIdentidadeObject", newValue)}
					/>
				</div>

				{ocultarCpfRg &&
					<div className={"sm:col-span-9"}>
						<Input
							type={"text"}
							label={"N° do CIN"}
							value={nubente.cin ?? undefined}
							validation={nubente.cin != null && nubente.cin != ""}
							required={true}
							onChange={(e) => handleInputChange("cin", e.target.value)}
							onBlur={(e) => handleCniChange(e.target.value)}
						/>
					</div>
				}

				{ocultarCni &&
					<>
						<div className={"sm:col-span-3"}>
							<Input
								type={"text"}
								label={"CPF"}
								value={nubente.cpf ?? undefined}
								validation={nubente.cpf != null && nubente.cpf != ""}
								required={true}
								onChange={(e) => handleInputChange("cpf", e.target.value)}
								onBlur={(e) => handleCpfChange(e.target.value)}
							/>
						</div>

						<div className={"sm:col-span-3"}>
							<Input
								type={"text"}
								label={"Documento de Identificação"}
								value={nubente.documentoIdentidade ?? undefined}
								validation={nubente.documentoIdentidade != null && nubente.documentoIdentidade != ""}
								required={true}
								onChange={(e) => handleInputChange("documentoIdentidade", e.target.value)}
							/>
						</div>

						<div className={"sm:col-span-3"}>
							<Input
								type={"text"}
								label={"Órgão Expedidor"}
								value={nubente.orgaoExpedidor ?? undefined}
								validation={nubente.orgaoExpedidor != null && nubente.orgaoExpedidor != ""}
								required={true}
								onChange={(e) => handleInputChange("orgaoExpedidor", e.target.value)}
								onBlur={(e) => handleOrgaoExpedidorChange(e.target.value)}
							/>
						</div>
					</>
				}

				<div className={"sm:col-span-12"}>
					<Input
						type={"text"}
						label={"Profissão"}
						value={nubente.profissao ?? undefined}
						validation={nubente.profissao != null && nubente.profissao != ""}
						required={true}
						onChange={(e) => handleInputChange("profissao", e.target.value)}
						onBlur={(e) => handleProfissaoChange(e.target.value)}
					/>
				</div>

				<div className={"sm:col-span-4"}>
					<Input
						type={"text"}
						label={"CEP"}
						value={CepHelper.formatarCep(nubente.cep ?? "")}
						maxLength={9}
						validation={nubente.cep != null && nubente.cep != ""}
						required={true}
						onChange={(e) => handleInputChange("cep", e.target.value)}
						onBlur={(e) => handleCepChange(e.target.value)}
					/>
				</div>

				<div className={"sm:col-span-6"}>
					<Input
						type={"text"}
						label={"Endereço"}
						value={nubente.endereco ?? undefined}
						validation={nubente.endereco != null && nubente.endereco != ""}
						required={true}
						onChange={(e) => handleInputChange("endereco", e.target.value)}
					/>
				</div>

				<div className={"sm:col-span-2"}>
					<Input
						type={"text"}
						label={"Número"}
						value={nubente.numero ?? undefined}
						validation={nubente.numero != null && nubente.numero != ""}
						required={true}
						onChange={(e) => handleInputChange("numero", e.target.value)}
					/>
				</div>

				<div className={"sm:col-span-3"}>
					<Input
						type={"text"}
						label={"Complemento"}
						value={nubente.complemento ?? undefined}
						onChange={(e) => handleInputChange("complemento", e.target.value)}
					/>
				</div>

				<div className={"sm:col-span-3"}>
					<Input
						type={"text"}
						label={"Bairro"}
						value={nubente.bairro ?? undefined}
						validation={nubente.bairro != null && nubente.bairro != ""}
						required={true}
						onChange={(e) => handleInputChange("bairro", e.target.value)}
					/>
				</div>

				<div className={"sm:col-span-3"}>
					<Input
						type={"text"}
						label={"Cidade"}
						value={nubente.cidade ?? undefined}
						validation={nubente.cidade != null && nubente.cidade != ""}
						required={true}
						onChange={(e) => handleInputChange("cidade", e.target.value)}
					/>
				</div>

				<div className={"sm:col-span-3"}>
					<Autocomplete
						disablePortal
						options={listaEstados}
						getOptionLabel={(option) => option.descricao}
						value={nubente.ufObject}
						size={"small"}
						renderInput={(params) => <TextField {...params} label="Estado" required={true} size={"small"}/>}
						onChange={(_, newValue) => handleInputChange("ufObject", newValue)}
					/>
				</div>
			</div>
		</div>
	);
};

export default Nubente;