import {Button, Dialog, DialogBody, DialogFooter, DialogHeader, Typography} from "@material-tailwind/react";
import {HiOutlineDownload} from "react-icons/hi";
import ButtonDefault from "components/Buttons/Default";
import ERotas from "enums/ERotas";
import React, {Dispatch, SetStateAction, useState} from "react";
import {IListarRequerimentoResult} from "interfaces/CommandsResults/RequerimentosResults/IListarRequerimentoResult";
import {RequerimentosService} from "services/RequerimentosService";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import EStrings from "enums/EStrings";
import {ToastHelper} from "helpers/ToastHelper";
import {useNavigate} from "react-router-dom";
import IAprovarEdicaoRequerimentoCommand from "interfaces/Commands/RequerimentosCommands/IAprovarEdicaoRequerimentoCommand";
import IReprovarEdicaoRequerimentoCommand from "interfaces/Commands/RequerimentosCommands/IReprovarEdicaoRequerimentoCommand";
import IDownloadRequerimentoComprovanteCommand from "interfaces/Commands/RequerimentosCommands/IDownloadRequerimentoComprovanteCommand";
import {BaseHelper} from "helpers/BaseHelper";
import {FileHelper} from "helpers/FileHelper";
import useData from "../../../../../contexts/dataProvider/useData";

interface IProps {
	requerimento?: IListarRequerimentoResult;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setAtualizarLista: Dispatch<SetStateAction<boolean>>;
}

const RequerimentoModalVisualizarComprovante = (props: IProps) => {
	const {
		requerimento,
		open,
		setOpen,
		setAtualizarLista
	} = props;

	const {auth} = useData();
	const navigate = useNavigate();
	const tokenVisao = auth?.token;

	const [desabilitarBotao, setDesabilitarBotao] = useState<boolean>(false);

	const handleDownloadComprovante = async (tokenRequerimento: string) => {
		const params: IDownloadRequerimentoComprovanteCommand = {
			token: tokenRequerimento
		};

		try {
			const result = await RequerimentosService.downloadComprovante(params, tokenVisao ?? "");

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					navigate(ERotas.LOGIN);
					return;
				}

				ToastHelper("warning", errors[0].message);
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);
			}

			ToastHelper("success", "Comprovante visualizado com sucesso.");
			FileHelper.show(body.data.base64, body.data.mimetype);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}
		}
	};

	const handleAprovarEdicao = async () => {
		setDesabilitarBotao(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			navigate(ERotas.LOGIN);
			return;
		}

		try {
			const params: IAprovarEdicaoRequerimentoCommand = {
				token: requerimento?.token ?? ""
			};

			const result = await RequerimentosService.aprovarEdicao(params, tokenVisao);

			if (!result) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				errors.forEach((item) => {
					ToastHelper("warning", item.message);
				});

				setDesabilitarBotao(false);
				return;
			}

			const body = result.data;

			if (!body) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return;
			}

			setDesabilitarBotao(false);
			setOpen(false);

			ToastHelper("success", "Edição aprovada com sucesso.");

			setAtualizarLista(true);
		} catch (error) {
			setDesabilitarBotao(false);

			if (error instanceof Error) {
				ToastHelper("error", error.message);
				setOpen(false);

				return;
			}

			ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			setOpen(false);

			return;
		}
	};

	const handleReprovarEdicao = async () => {
		setDesabilitarBotao(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			return navigate(ERotas.LOGIN);
		}

		try {
			const params: IReprovarEdicaoRequerimentoCommand = {
				token: requerimento?.token ?? ""
			};

			const result = await RequerimentosService.reprovarEdicao(params, tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				errors.forEach((item) => {
					ToastHelper("warning", item.message);
				});

				return;
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return;
			}

			ToastHelper("success", "Edição reprovada com sucesso.");
			setAtualizarLista(true);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);

				return;
			}

			ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			return;
		} finally {
			setDesabilitarBotao(false);
			setOpen(false);
		}
	};

	return (
		<Dialog open={open} handler={() => setOpen(!open)} size={"sm"} animate={BaseHelper.AnimationDialog}>
			<DialogHeader>Permissão de Edição # {requerimento?.id}</DialogHeader>
			<DialogBody>
				<Typography variant={"small"} className={"font-monospace text-justify"}>
					O Celebrante solicitou a permissão para editar este requerimento.
				</Typography>
				&emsp;
				<Typography variant={"small"} className={"font-monospace text-justify"}>
					Segue abaixo o link de download do comprovante de transferência bancária realizada pelo celebrante:
				</Typography>
				&emsp;
				<Button fullWidth={true} className={"flex gap-3 items-center"} size={"sm"} variant={"gradient"} color={"blue"} onClick={() => handleDownloadComprovante(requerimento?.token ?? "")}>
					<HiOutlineDownload className={"w-4 h-4 ml-auto"}/>
					<Typography variant="small" className="font-normal  mr-auto">
						Baixar Comprovante
					</Typography>
				</Button>
			</DialogBody>
			<DialogFooter className={"space-x-2"}>
				<ButtonDefault color={"red"} description={"Reprovar"} onClick={handleReprovarEdicao} desabilitar={desabilitarBotao}/>
				<ButtonDefault color={"green"} description={"Aprovar"} onClick={handleAprovarEdicao} desabilitar={desabilitarBotao}/>
			</DialogFooter>
		</Dialog>
	);
};

export default RequerimentoModalVisualizarComprovante;