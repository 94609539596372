import {Api} from "providers/Api";
import {ApiResult} from "interfaces/ApiResult";
import {IListarDadosBancariosResult} from "interfaces/CommandsResults/DadosBancariosResults/IListarDadosBancariosResult";
import AtivarDesativarCommand from "interfaces/Commands/DadosBancariosCommands/AtivarDesativarCommand";
import {ISalvarAtualizarResult} from "interfaces/CommandsResults/DadosBancariosResults/ISalvarAtualizarResult";
import {IAtivarDesativarResult} from "interfaces/CommandsResults/DadosBancariosResults/IAtivarDesativarResult";
import {IListarDadosBancariosAtivosResult} from "interfaces/CommandsResults/DadosBancariosResults/IListarDadosBancariosAtivosResult";

const controller = "/dadosBancarios";

const listar = (token: string) => Api.post<ApiResult<IListarDadosBancariosResult[]>>(`${controller}/listar`, {}, {
	headers: {
		token: token
	}
});
const ativarDesativar = (data: AtivarDesativarCommand, token: string) => Api.post<ApiResult<IAtivarDesativarResult>>(`${controller}/ativarDesativar`, data, {
	headers: {
		token: token
	}
});
const salvarAtualizar = (data: ISalvarAtualizarResult, token: string) => Api.post<ApiResult<ISalvarAtualizarResult>>(`${controller}/salvarAtualizar`, data, {
	headers: {
		token: token
	}
});
const listarAtivos = (token: string) => Api.post<ApiResult<IListarDadosBancariosAtivosResult>>(`${controller}/listarAtivos`, {}, {
	headers: {
		token: token
	}
});

export const DadosBancariosService = {
	listar,
	ativarDesativar,
	salvarAtualizar,
	listarAtivos
};