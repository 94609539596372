import {Card as Cd, CardBody, CardFooter, Typography} from "@material-tailwind/react";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import CardProps from "./_interfaces/CardProps";

const Card = (props: CardProps) => {
	const navigate = useNavigate();
	const {
		titulo,
		subtitulo,
		quantidade,
		color,
		rota
	} = props;

	const [animate, setAnimate] = useState<boolean>(false);

	useEffect(() => {
		setAnimate(true);
		const timeoutId = setTimeout(() => {
			setAnimate(false);
		}, 500); // Defina o tempo da animação aqui, deve ser o mesmo que a duração da animação CSS

		return () => clearTimeout(timeoutId);
	}, [quantidade]);

	const handleRedirectPagina = () => {
		navigate(rota);
	};

	return (
		<Cd className={"h-full cursor-pointer"} onClick={handleRedirectPagina}>
			<CardBody className={"text-center h-full mb-0 pb-0"}>
				<Typography variant={"h4"} color={color} className={"font-bold"}>{titulo}</Typography>
				<Typography variant={"h5"} className={"font-light"}>{subtitulo}</Typography>
			</CardBody>
			<CardFooter className={"text-center mt-0 pt-0"}>
				<Typography variant={"h4"} className={`font-monospace ${animate ? "count-up" : ""}`}>{quantidade ?? 0}</Typography>
			</CardFooter>
		</Cd>
	);
};

export default Card;