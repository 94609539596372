import {IListarTermoResult} from "interfaces/CommandsResults/TermosResults/IListarTermoResult";
import React from "react";
import {useNavigate} from "react-router-dom";
import {Dialog, DialogBody, DialogFooter, DialogHeader, Typography} from "@material-tailwind/react";
import ButtonDefault from "components/Buttons/Default";
import ERotas from "enums/ERotas";
import {BaseHelper} from "helpers/BaseHelper";
import useData from "../../../../../contexts/dataProvider/useData";

interface IProps {
	termo?: IListarTermoResult;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const TermoModalReprovado = (props: IProps) => {
	const {
		termo,
		open,
		setOpen
	} = props;

	const navigate = useNavigate();
	const {user} = useData();
	const token = user?.token;

	const handleRedirect = (rota: string) => {
		navigate(rota);
	};

	return (
		<Dialog open={open} handler={() => setOpen(!open)} size={"sm"} animate={BaseHelper.AnimationDialog}>
			<DialogHeader>Termo Reprovado</DialogHeader>
			<DialogBody>
				<Typography variant={"small"} className={"font-monospace"}>
					{termo?.motivo_reprovacao ?? ""}
				</Typography>
			</DialogBody>
			<DialogFooter className={"space-x-2"}>
				<ButtonDefault color={"red"} description={"Fechar"} onClick={() => setOpen(false)}/>
				{termo?.token_solicitante === token &&
					<ButtonDefault color={"amber"} description={"Corrigir"} onClick={() => handleRedirect(`${ERotas.TERMO_SOLICITAR_EDICAO}/${termo?.token}`)}/>
				}
			</DialogFooter>
		</Dialog>
	);
};

export default TermoModalReprovado;