import {useNavigate} from "react-router-dom";
import React, {FormEvent, useEffect, useState} from "react";
import {IBuscarRequerimentoResult} from "interfaces/CommandsResults/RequerimentosResults/IBuscarRequerimentoResult";
import Input from "components/Inputs/Default";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import EStrings from "enums/EStrings";
import ERotas from "enums/ERotas";
import {ParametrosService} from "services/ParametrosService";
import {IBuscarComboboxResult} from "interfaces/CommandsResults/ParametrosResults/IBuscarComboboxResult";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {CepHelper} from "helpers/CepHelper";
import SpinnerComponent from "components/Spinner";
import {ToastHelper} from "helpers/ToastHelper";
import {DatePicker, TimePicker} from "@mui/x-date-pickers";
import {DataHelper} from "helpers/DataHelper";
import ButtonDefault from "components/Buttons/Default";
import {Button, Dialog, DialogFooter, DialogHeader} from "@material-tailwind/react";
import ButtonSubmit from "components/Buttons/Submit";
import ISalvarAtualizarRequerimentoCommand from "interfaces/Commands/RequerimentosCommands/ISalvarAtualizarRequerimentoCommand";
import {RequerimentosService} from "services/RequerimentosService";
import EAcesso from "enums/EAcesso";
import {HiOutlineArrowTopRightOnSquare} from "react-icons/hi2";
import IBuscarModeloPdfRequerimentoCommand from "interfaces/Commands/RequerimentosCommands/IBuscarModeloPdfRequerimentoCommand";
import {PdfHelper} from "helpers/PdfHelper";
import {BaseHelper} from "helpers/BaseHelper";
import RequerimentoAlertaCriacao from "../../cards/alertaCriacao";
import Nubente from "./_components/nubente";
import {createRequerimentoDefault, RequerimentoCommand} from "./_interfaces/RequerimentoCommand";
import {createNubenteDefault, NubenteCommand} from "./_interfaces/NubenteCommand";
import useData from "../../../../../contexts/dataProvider/useData";

interface IProps {
	data?: IBuscarRequerimentoResult | undefined;
	textoCriarAtualizar: string;
	novo: boolean;
}

const RequerimentoFormulario = (props: IProps) => {
	const {
		data,
		textoCriarAtualizar,
		novo
	} = props;
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const {auth, user} = useData();
	const navigate = useNavigate();
	const tokenVisao = auth?.token;
	const tokenUsuario = user?.token;
	const acesso = auth?.acesso?.toString();

	const [requerimento, setRequerimento] = useState<RequerimentoCommand>(createRequerimentoDefault({}));
	const [primeiroNubente, setPrimeiroNubente] = useState<NubenteCommand>(createNubenteDefault({}));
	const [segundoNubente, setSegundoNubente] = useState<NubenteCommand>(createNubenteDefault({}));

	const [listaCartorios, setListaCartorios] = useState<IBuscarComboboxResult[]>([]);
	const [listaGeneros, setListaGeneros] = useState<IBuscarComboboxResult[]>([]);
	const [listaEstados, setListaEstados] = useState<IBuscarComboboxResult[]>([]);
	const [listaEstadosCivis, setListaEstadosCivis] = useState<IBuscarComboboxResult[]>([]);
	const [listaTiposIdentidades, setListaTiposIdentidades] = useState<IBuscarComboboxResult[]>([]);
	const [listaNacionalidades, setListaNacionalidades] = useState<IBuscarComboboxResult[]>([]);

	const [openDialog, setOpenDialog] = useState<boolean>(false);
	const [desabilitarBotao, setDesabilitarBotao] = useState<boolean>(false);

	const handleOpen = () => {
		setOpenDialog(!openDialog);
	};

	// region EFFECTS
	useEffect(() => {
		popularDados(data);
		buscarCombobox();

	}, [props.data]);

	useEffect(() => {
		const selectedOption = listaCartorios.find((option) => option.token === requerimento.tokenCartorio);

		handleInputChange("tokenCartorioObject", selectedOption ?? null);
	}, [requerimento.tokenCartorio, listaCartorios]);

	useEffect(() => {
		const selectedOption = listaEstados.find((option) => option.sigla === requerimento.ufCartorio);

		handleInputChange("ufCartorioObject", selectedOption ?? null);
	}, [requerimento.ufCartorio, listaEstados]);

	useEffect(() => {
		const selectedOption = listaEstados.find((option) => option.sigla === requerimento.ufLocal);

		handleInputChange("ufLocalObject", selectedOption ?? null);
	}, [requerimento.ufLocal, listaEstados]);
	// endregion

	const handleInputChange = (name: string, value: string | IBuscarComboboxResult | Date | null) => {
		setRequerimento((prevState) => ({
			...prevState,
			[name]: value
		}));
	};

	// region POPULAR DADOS
	const popularDados = (data: IBuscarRequerimentoResult | undefined) => {
		setIsLoading(true);

		if (data != null) {
			setRequerimento({
				tokenCartorio: data.token_cartorio,
				tokenCartorioObject: null,
				cepCartorio: data.cep_cartorio,
				enderecoCartorio: data.endereco_cartorio,
				numeroCartorio: data.numero_cartorio,
				complementoCartorio: data.complemento_cartorio,
				bairroCartorio: data.bairro_cartorio,
				cidadeCartorio: data.cidade_cartorio,
				ufCartorio: data.uf_cartorio,
				ufCartorioObject: null,
				dataEvento: DataHelper.converterStringToDate(data.data_evento),
				horaEvento: data.hora_evento,
				nomeLocal: data.nome_local,
				cepLocal: data.cep_local,
				enderecoLocal: data.endereco_local,
				numeroLocal: data.numero_local,
				complementoLocal: data.complemento_local,
				bairroLocal: data.bairro_local,
				cidadeLocal: data.cidade_local,
				ufLocal: data.uf_local,
				ufLocalObject: null
			});

			setPrimeiroNubente({
				token: data.nubentes[0].token,
				nome: data.nubentes[0].nome,
				dataNascimento: DataHelper.converterStringToDate(data.nubentes[0].data_nascimento),
				nacionalidade: data.nubentes[0].nacionalidade,
				estadoCivil: data.nubentes[0].estado_civil,
				estadoCivilObject: null,
				genero: data.nubentes[0].genero,
				generoObject: null,
				tipoIdentidade: data.nubentes[0].tipo_identidade,
				tipoIdentidadeObject: null,
				cin: data.nubentes[0].cin,
				cpf: data.nubentes[0].cpf,
				documentoIdentidade: data.nubentes[0].numero_identidade,
				orgaoExpedidor: data.nubentes[0].orgao_expedidor,
				profissao: data.nubentes[0].profissao,
				cep: data.nubentes[0].cep,
				endereco: data.nubentes[0].endereco,
				numero: data.nubentes[0].numero,
				complemento: data.nubentes[0].complemento,
				bairro: data.nubentes[0].bairro,
				cidade: data.nubentes[0].cidade,
				uf: data.nubentes[0].uf,
				ufObject: null
			});

			setSegundoNubente({
				token: data.nubentes[1].token,
				nome: data.nubentes[1].nome,
				dataNascimento: DataHelper.converterStringToDate(data.nubentes[1].data_nascimento),
				nacionalidade: data.nubentes[1].nacionalidade,
				estadoCivil: data.nubentes[1].estado_civil,
				estadoCivilObject: null,
				tipoIdentidade: data.nubentes[1].tipo_identidade,
				tipoIdentidadeObject: null,
				cin: data.nubentes[1].cin,
				cpf: data.nubentes[1].cpf,
				genero: data.nubentes[1].genero,
				generoObject: null,
				documentoIdentidade: data.nubentes[1].numero_identidade,
				orgaoExpedidor: data.nubentes[1].orgao_expedidor,
				profissao: data.nubentes[1].profissao,
				cep: data.nubentes[1].cep,
				endereco: data.nubentes[1].endereco,
				numero: data.nubentes[1].numero,
				complemento: data.nubentes[1].complemento,
				bairro: data.nubentes[1].bairro,
				cidade: data.nubentes[1].cidade,
				uf: data.nubentes[1].uf,
				ufObject: null
			});
		}

		setIsLoading(false);
	};

	const buscarCombobox = async () => {
		setIsLoading(true);

		try {
			if (!tokenVisao || tokenVisao === "") {
				LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

				return navigate(ERotas.LOGIN);
			}

			const result = await ParametrosService.buscarCombobox({relatorio: "REQUERIMENTO"}, tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return navigate(-1);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					return navigate(ERotas.LOGIN);
				}

				ToastHelper("warning", errors[0].message);

				return navigate(-1);
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return navigate(-1);
			}

			const data = body.data;

			popularCombobox(data);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return navigate(-1);
		} finally {
			setIsLoading(false);
		}
	};

	function popularCombobox(dados: IBuscarComboboxResult[]) {
		const cartorios = dados.filter(item => item.relatorio.includes("CARTORIOS"));
		const generos = dados.filter(item => item.relatorio.includes("GENEROS"));
		const estados = dados.filter(item => item.relatorio.includes("ESTADOS"));
		const estadosCivis = dados.filter(item => item.relatorio.includes("ESTADO_CIVIL"));
		const tiposIdentidades = dados.filter(item => item.relatorio.includes("TIPOS_IDENTIDADES"));
		const nacionalidades = dados.filter(item => item.relatorio.includes("NACIONALIDADES"));

		setListaCartorios(cartorios);
		setListaGeneros(generos);
		setListaEstados(estados);
		setListaEstadosCivis(estadosCivis);
		setListaTiposIdentidades(tiposIdentidades);
		setListaNacionalidades(nacionalidades);
	}

	// endregion

	const handleOpenModeloChange = async () => {
		setIsLoading(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			navigate(ERotas.LOGIN);
			return;
		}

		try {
			const params: IBuscarModeloPdfRequerimentoCommand = {
				token: data?.token ?? ""
			};

			const result = await RequerimentosService.buscarModeloPdf(params, tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);
				setIsLoading(false);

				return navigate(-1);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					setIsLoading(false);
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					navigate(ERotas.LOGIN);
					return;
				}

				setIsLoading(false);
				ToastHelper("warning", errors[0].message);

				return navigate(-1);
			}

			const body = result.data;

			if (!body) {
				setIsLoading(false);
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return navigate(-1);
			}

			setIsLoading(false);
			PdfHelper.showPdf(body.data.base64);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			setIsLoading(false);
			return navigate(-1);
		}
	};

	const handleCepCartorioChange = async (value: string) => {
		setIsLoading(true);

		value = CepHelper.formatarCep(value);

		handleInputChange("cepCartorio", value);

		const result = await CepHelper.buscarCep(value);

		const setValuesEmpty = () => {
			handleInputChange("enderecoCartorio", "");
			handleInputChange("bairroCartorio", "");
			handleInputChange("cidadeCartorio", "");
			handleInputChange("ufCartorio", "");
		};

		if (result == null) {
			setValuesEmpty();
			setIsLoading(false);

			return;
		}

		handleInputChange("enderecoCartorio", result.logradouro);
		handleInputChange("bairroCartorio", result.bairro);
		handleInputChange("cidadeCartorio", result.localidade);
		handleInputChange("ufCartorio", result.uf);

		setIsLoading(false);
	};

	const handleCepLocalChange = async (value: string) => {
		setIsLoading(true);

		value = CepHelper.formatarCep(value);

		handleInputChange("cepLocal", value);

		const result = await CepHelper.buscarCep(value);

		const setValuesEmpty = () => {
			handleInputChange("enderecoLocal", "");
			handleInputChange("bairroLocal", "");
			handleInputChange("cidadeLocal", "");
			handleInputChange("ufLocal", "");
		};

		if (result == null) {
			setValuesEmpty();
			setIsLoading(false);

			return;
		}

		handleInputChange("enderecoLocal", result.logradouro);
		handleInputChange("bairroLocal", result.bairro);
		handleInputChange("cidadeLocal", result.localidade);
		handleInputChange("ufLocal", result.uf);

		setIsLoading(false);
	};

	function LogToast(tipo: string, mensagem: string) {
		ToastHelper(tipo, mensagem);

		setDesabilitarBotao(false);
		setOpenDialog(false);
	}

	const enviarFormulario = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setDesabilitarBotao(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			navigate(ERotas.LOGIN);
			return;
		}

		try {
			const params: ISalvarAtualizarRequerimentoCommand = {
				token: props.data?.token ?? "",
				token_cartorio: requerimento.tokenCartorioObject?.token ?? "",
				cep_cartorio: requerimento.cepCartorio ?? "",
				endereco_cartorio: requerimento.enderecoCartorio ?? "",
				numero_cartorio: requerimento.numeroCartorio ?? "",
				complemento_cartorio: requerimento.complementoCartorio ?? "",
				bairro_cartorio: requerimento.bairroCartorio ?? "",
				cidade_cartorio: requerimento.cidadeCartorio ?? "",
				uf_cartorio: requerimento.ufCartorioObject?.token ?? "",
				nubentes: [
					{
						token: primeiroNubente.token ?? "",
						nome: primeiroNubente.nome ?? "",
						data_nascimento: DataHelper.getDateTime(primeiroNubente.dataNascimento, "YYYY-MM-DD"),
						nacionalidade: primeiroNubente.nacionalidade ?? "",
						estado_civil: primeiroNubente.estadoCivilObject?.token ?? "",
						genero: primeiroNubente.generoObject?.token ?? "",
						tipo_identidade: primeiroNubente.tipoIdentidadeObject?.token ?? "",
						cin: primeiroNubente.cin,
						cpf: primeiroNubente.cpf,
						documento_identidade: primeiroNubente.documentoIdentidade,
						orgao_expedidor: primeiroNubente.orgaoExpedidor ?? "",
						profissao: primeiroNubente.profissao ?? "",
						cep: primeiroNubente.cep ?? "",
						endereco: primeiroNubente.endereco ?? "",
						numero: primeiroNubente.numero ?? "",
						complemento: primeiroNubente.complemento,
						bairro: primeiroNubente.bairro ?? "",
						cidade: primeiroNubente.cidade ?? "",
						uf: primeiroNubente.ufObject?.token ?? ""
					},
					{
						token: segundoNubente.token ?? "",
						nome: segundoNubente.nome ?? "",
						data_nascimento: DataHelper.getDateTime(segundoNubente.dataNascimento, "YYYY-MM-DD"),
						nacionalidade: segundoNubente.nacionalidade ?? "",
						estado_civil: segundoNubente.estadoCivilObject?.token ?? "",
						genero: segundoNubente.generoObject?.token ?? "",
						tipo_identidade: segundoNubente.tipoIdentidadeObject?.token ?? "",
						cin: segundoNubente.cin,
						cpf: segundoNubente.cpf,
						documento_identidade: segundoNubente.documentoIdentidade,
						orgao_expedidor: segundoNubente.orgaoExpedidor ?? "",
						profissao: segundoNubente.profissao ?? "",
						cep: segundoNubente.cep ?? "",
						endereco: segundoNubente.endereco ?? "",
						numero: segundoNubente.numero ?? "",
						complemento: segundoNubente.complemento,
						bairro: segundoNubente.bairro ?? "",
						cidade: segundoNubente.cidade ?? "",
						uf: segundoNubente.ufObject?.token ?? ""
					}
				],
				data_evento: DataHelper.getDateTime(requerimento.dataEvento, "YYYY-MM-DD"),
				hora_evento: requerimento.horaEvento ?? "",
				nome_local: requerimento.nomeLocal ?? "",
				cep_local: requerimento.cepLocal ?? "",
				endereco_local: requerimento.enderecoLocal ?? "",
				numero_local: requerimento.numeroLocal ?? "",
				complemento_local: requerimento.complementoLocal,
				bairro_local: requerimento.bairroLocal ?? "",
				cidade_local: requerimento.cidadeLocal ?? "",
				uf_local: requerimento.ufLocalObject?.token ?? ""
			};

			const result = await RequerimentosService.salvarAtualizar(params, tokenVisao);

			if (!result) {
				return LogToast("warning", EStrings.ERRO_RESULT);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				errors.forEach((item) => {
					ToastHelper("warning", item.message);
				});

				setOpenDialog(false);
				setDesabilitarBotao(false);

				return;
			}

			const body = result.data;

			if (!body) {
				return LogToast("warning", EStrings.ERRO_BUSCAR_DADOS);
			}

			const data = body.data;

			LogToast("success", `Requerimento ${params.token == "" ? "criado" : "atualizado"} com sucesso!`);

			setTimeout(() => {
				if (acesso === EAcesso.MASTER.toString()) {
					navigate(`${ERotas.REQUERIMENTO_VISUALIZAR}/${data.token}`);
				} else if (acesso === EAcesso.CELEBRANTE.toString()) {
					navigate(`${ERotas.REQUERIMENTO_EDITAR}/${data.token}`);
				} else {
					navigate(-1);
				}
			}, EStrings.TIMEOUT);
		} catch (error) {
			if (error instanceof Error) {
				return LogToast("error", error.message);
			}

			return LogToast("error", EStrings.ERRO_NAO_MAPEADO);
		}
	};

	return (
		<form id={"formRequerimento"} onSubmit={enviarFormulario} noValidate>
			{isLoading &&
				<SpinnerComponent/>
			}

			<div className={"space-y-4 mb-3"}>
				{acesso === EAcesso.CELEBRANTE.toString() &&
					<RequerimentoAlertaCriacao/>
				}

				{data?.token_solicitante === tokenUsuario && acesso === EAcesso.CELEBRANTE.toString() &&
					<div className={"grid gap-x-6 gap-y-8 sm:grid-cols-12"}>
						<div className={"sm:col-span-12 flex justify-center"}>
							<Button
								variant="gradient"
								color={"light-blue"}
								size={"sm"}
								className="rounded-full flex items-center gap-3"
								onClick={handleOpenModeloChange}
							>
								<HiOutlineArrowTopRightOnSquare className={"h-5 w-5"}/>
								Visualize seu Modelo
							</Button>
						</div>
					</div>
				}

				<legend className={"pb-3"}>Dados do Cartório</legend>
				<div className={"grid gap-x-6 gap-y-8 sm:grid-cols-12"}>
					<div className={"sm:col-span-12 md:col-span-12"}>
						<Autocomplete
							color={requerimento.tokenCartorio != "" ? "success" : "info"}
							disablePortal
							options={listaCartorios}
							getOptionLabel={(option) => option.descricao}
							value={requerimento.tokenCartorioObject}
							size={"small"}
							renderInput={(params) => <TextField {...params} label="Cartório" size={"small"} required={true}/>}
							onChange={(_, newValue) => handleInputChange("tokenCartorioObject", newValue)}
						/>
					</div>
					<div className={"sm:col-span-4"}>
						<Input
							type={"text"}
							label={"CEP"}
							value={CepHelper.formatarCep(requerimento.cepCartorio ?? "")}
							maxLength={9}
							validation={requerimento.cepCartorio != ""}
							required={true}
							onChange={(e) => handleInputChange("cepCartorio", e.target.value)}
							onBlur={(e) => handleCepCartorioChange(e.target.value)}
						/>
					</div>

					<div className={"sm:col-span-6"}>
						<Input
							type={"text"}
							label={"Endereço"}
							value={requerimento.enderecoCartorio ?? undefined}
							validation={requerimento.enderecoCartorio != ""}
							required={true}
							onChange={(e) => handleInputChange("enderecoCartorio", e.target.value)}
						/>
					</div>

					<div className={"sm:col-span-2"}>
						<Input
							type={"text"}
							label={"Número"}
							value={requerimento.numeroCartorio ?? undefined}
							validation={requerimento.numeroCartorio != ""}
							required={true}
							onChange={(e) => handleInputChange("numeroCartorio", e.target.value)}
						/>
					</div>

					<div className={"sm:col-span-3"}>
						<Input
							type={"text"}
							label={"Complemento"}
							value={requerimento.complementoCartorio ?? undefined}
							onChange={(e) => handleInputChange("complementoCartorio", e.target.value)}
						/>
					</div>

					<div className={"sm:col-span-3"}>
						<Input
							type={"text"}
							label={"Bairro"}
							value={requerimento.bairroCartorio ?? undefined}
							validation={requerimento.bairroCartorio != ""}
							required={true}
							onChange={(e) => handleInputChange("bairroCartorio", e.target.value)}
						/>
					</div>

					<div className={"sm:col-span-3"}>
						<Input
							type={"text"}
							label={"Cidade"}
							value={requerimento.cidadeCartorio ?? undefined}
							validation={requerimento.cidadeCartorio != ""}
							required={true}
							onChange={(e) => handleInputChange("cidadeCartorio", e.target.value)}
						/>
					</div>

					<div className={"sm:col-span-3"}>
						<Autocomplete
							color={requerimento.ufCartorioObject ? "success" : "info"}
							disablePortal
							options={listaEstados}
							getOptionLabel={(option) => option.descricao}
							value={requerimento.ufCartorioObject}
							size={"small"}
							renderInput={(params) => <TextField {...params} label="Estado" required={true}/>}
							onChange={(_, newValue) => handleInputChange("ufCartorioObject", newValue)}
						/>
					</div>
				</div>
			</div>

			<Nubente
				setIsLoading={setIsLoading}
				title={"Dados do Primeiro Nubente (Noivo)"}
				nubente={primeiroNubente}
				setNubente={setPrimeiroNubente}
				listaEstadosCivis={listaEstadosCivis}
				listaGeneros={listaGeneros}
				listaEstados={listaEstados}
				listaTiposIdentidades={listaTiposIdentidades}
			/>

			<Nubente
				setIsLoading={setIsLoading}
				title={"Dados do Segundo Nubente (Noiva)"}
				nubente={segundoNubente}
				setNubente={setSegundoNubente}
				listaEstadosCivis={listaEstadosCivis}
				listaGeneros={listaGeneros}
				listaEstados={listaEstados}
				listaTiposIdentidades={listaTiposIdentidades}
			/>

			<div className={"space-y-4 mb-3"}>
				<legend className={"pb-3"}> Dados do Evento</legend>
				<div className={"grid gap-x-6 gap-y-8 sm:grid-cols-12"}>
					<div className={"sm:col-span-6"}>
						<DatePicker
							label="Data do Evento"
							format={"dd/MM/yyyy"}
							value={requerimento.dataEvento}
							onChange={(e) => handleInputChange("dataEvento", e)}
							minDate={new Date()}
							maxDate={DataHelper.addDays(90)}
							yearsPerRow={3}
							slotProps={{
								textField: {
									fullWidth: true,
									required: true,
									size: "small",
									color: "success"
								}
							}}
						/>
					</div>

					<div className={"sm:col-span-6"}>
						<TimePicker
							label="Hora do Evento"
							format={"HH:mm"}
							value={new Date(`2023-09-11T${requerimento.horaEvento}`)}
							onChange={(e) => handleInputChange("horaEvento", DataHelper.getDateTime(e, "HH:mm"))}
							ampm={false}
							slotProps={{
								textField: {
									fullWidth: true,
									required: true,
									size: "small",
									color: "warning"
								}
							}}
						/>
					</div>

					<div className={"sm:col-span-12"}>
						<Input
							type={"text"}
							label={"Nome do Local"}
							value={requerimento.nomeLocal ?? undefined}
							required={false}
							onChange={(e) => handleInputChange("nomeLocal", e.target.value)}
						/>
					</div>

					<div className={"sm:col-span-4"}>
						<Input
							type={"text"}
							label={"CEP"}
							value={requerimento.cepLocal ?? undefined}
							maxLength={9}
							validation={requerimento.cepLocal != ""}
							required={true}
							onChange={(e) => handleInputChange("cepLocal", e.target.value)}
							onBlur={(e) => handleCepLocalChange(e.target.value)}
						/>
					</div>

					<div className={"sm:col-span-6"}>
						<Input
							type={"text"}
							label={"Endereço"}
							value={requerimento.enderecoLocal ?? undefined}
							validation={requerimento.enderecoLocal != ""}
							required={true}
							onChange={(e) => handleInputChange("enderecoLocal", e.target.value)}
						/>
					</div>

					<div className={"sm:col-span-2"}>
						<Input
							type={"text"}
							label={"Número"}
							value={requerimento.numeroLocal ?? undefined}
							validation={requerimento.numeroLocal != ""}
							required={true}
							onChange={(e) => handleInputChange("numeroLocal", e.target.value)}
						/>
					</div>

					<div className={"sm:col-span-3"}>
						<Input
							type={"text"}
							label={"Complemento"}
							value={requerimento.complementoLocal ?? undefined}
							onChange={(e) => handleInputChange("complementoLocal", e.target.value)}
						/>
					</div>

					<div className={"sm:col-span-3"}>
						<Input
							type={"text"}
							label={"Bairro"}
							value={requerimento.bairroLocal ?? undefined}
							validation={requerimento.bairroLocal != ""}
							required={true}
							onChange={(e) => handleInputChange("bairroLocal", e.target.value)}
						/>
					</div>

					<div className={"sm:col-span-3"}>
						<Input
							type={"text"}
							label={"Cidade"}
							value={requerimento.cidadeLocal ?? undefined}
							validation={requerimento.cidadeLocal != ""}
							required={true}
							onChange={(e) => handleInputChange("cidadeLocal", e.target.value)}
						/>
					</div>

					<div className={"sm:col-span-3"}>
						<Autocomplete
							disablePortal
							options={listaEstados}
							getOptionLabel={(option) => option.descricao}
							value={requerimento.ufLocalObject}
							size={"small"}
							renderInput={(params) => <TextField {...params} label="Estado" required={true} size={"small"}/>}
							onChange={(_, newValue) => handleInputChange("ufLocalObject", newValue)}
						/>
					</div>
				</div>
			</div>

			<div className="grid w-full gap-x-6 gap-y-8 sm:grid-cols-12 mt-6 mb-1">
				<div className="sm:col-span-12 ml-auto mr-auto">
					<ButtonDefault description={textoCriarAtualizar} onClick={handleOpen}/>
					<Dialog open={openDialog} handler={handleOpen} size={"sm"} animate={BaseHelper.AnimationDialog}>
						<DialogHeader>Tem certeza que deseja {novo ? "criar" : "atualizar"} o requerimento?</DialogHeader>
						<DialogFooter className={"space-x-2"}>
							<ButtonDefault color={"red"} description={"Cancelar"} onClick={handleOpen} desabilitar={desabilitarBotao}/>
							<ButtonSubmit form={"formRequerimento"} color={"green"} description={"Confirmar"} desabilitar={desabilitarBotao}/>
						</DialogFooter>
					</Dialog>
				</div>
			</div>
		</form>
	);
};

export default RequerimentoFormulario;