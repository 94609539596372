import {Button} from "@material-tailwind/react";
import styles from "./ButtonSubmit.module.scss";
import classNames from "classnames";
import ButtonSubmitProps from "./_interfaces/ButtonSubmitProps";

const ButtonSubmit = (props: ButtonSubmitProps) => {
	const {
		form = "form",
		desabilitar = false,
		fullWidth = false,
		color = "amber",
		description = ""
	} = props;

	return (
		<Button
			type={"submit"}
			form={form}
			ripple={true}
			variant={"gradient"}
			size={"sm"}
			color={color}
			className={classNames({
				["rounded-full"]: true,
				[styles.button]: true
			})}
			fullWidth={fullWidth}
			disabled={desabilitar}
		>
			{desabilitar &&
				"Aguardando..."
			}

			{!desabilitar &&
				description
			}
		</Button>
	);
};

export default ButtonSubmit;