import StorageHelper from "../../../helpers/StorageHelper";

interface AuthResult {
	email: string;
	token: string;
	acesso: number;
	quantidade_tentativas: number;
}

const deserializeAuth = (): AuthResult | null => {
	const stored = StorageHelper.get(localStorage, "auth");

	if (stored) {
		try {
			return stored;
		} catch (e) {
			console.error("Erro ao desserializar auth:", e);
		}
	}

	return null;
};

export {deserializeAuth};
export type {AuthResult};