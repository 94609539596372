import {IBuscarComboboxResult} from "../ParametrosResults/IBuscarComboboxResult";

interface TestemunhaTermoResult {
	[key: string]: string | IBuscarComboboxResult | Date | null;

	nome: string;
	data_nascimento: string;
	data_nascimento_date: Date | null;
	nacionalidade: string;
	estado_civil: string;
	estado_civil_object: IBuscarComboboxResult | null;
	genero: string;
	genero_object: IBuscarComboboxResult | null;
	tipo_identidade: string;
	tipo_identidade_object: IBuscarComboboxResult | null;
	cin: string | null;
	cpf: string | null;
	documento_identificacao: string | null;
	orgao_expedidor: string;
	profissao: string;
	cep: string;
	endereco: string;
	numero: string;
	complemento: string;
	bairro: string;
	cidade: string;
	uf: string;
	uf_object: IBuscarComboboxResult | null;
	nome_pai: string;
	nome_mae: string;
	token: string;
}

const createTestemunhaTermoDefault = (overrides: Partial<TestemunhaTermoResult>): TestemunhaTermoResult => ({
	nome: "",
	data_nascimento: "",
	data_nascimento_date: null,
	nacionalidade: "",
	estado_civil: "",
	estado_civil_object: null,
	genero: "",
	genero_object: null,
	tipo_identidade: "",
	tipo_identidade_object: null,
	cin: null,
	cpf: null,
	documento_identificacao: null,
	orgao_expedidor: "",
	profissao: "",
	cep: "",
	endereco: "",
	numero: "",
	complemento: "",
	bairro: "",
	cidade: "",
	uf: "",
	uf_object: null,
	nome_pai: "",
	nome_mae: "",
	token: "",
	...overrides
});

export {createTestemunhaTermoDefault};
export type {TestemunhaTermoResult};