import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import ERotas from "enums/ERotas";
import Login from "pages/login";
import Inicio from "pages/inicio";
import DefaultLayoutNoLogin from "layout/DefaultLayoutNoLogin";
import EsqueciSenha from "pages/esqueciSenha";
import UsuarioEditar from "pages/usuario/editar";
import DefaultLayout from "layout/DefaultLayout";
import UsuarioListar from "pages/usuario/listar";
import UsuarioAdicionar from "pages/usuario/adicionar";
import UsuarioPrimeiroAcesso from "pages/usuario/primeiroAcesso";
import ListarCartorios from "pages/cartorios/listar";
import DadoBancarioListar from "pages/dadosBancarios/listar";
import RequerimentoListar from "pages/requerimento/listas/todos";
import RequerimentoEditar from "pages/requerimento/editar";
import RequerimentoSolicitarEdicao from "pages/requerimento/solicitarEdicao";
import RequerimentoListarAguardando from "pages/requerimento/listas/aguardando";
import RequerimentoListarAprovado from "pages/requerimento/listas/aprovado";
import RequerimentoListarCancelado from "pages/requerimento/listas/cancelado";
import ListarRequerimentosPendentes from "pages/requerimento/listas/pendente";
import ListarRequerimentosReprovados from "pages/requerimento/listas/reprovado";
import RequerimentoListarSolicitacaoCancelamento from "pages/requerimento/listas/solicitacaoCancelamento";
import RequerimentoAdicionar from "pages/requerimento/adicionar";
import RequerimentoVisualizar from "pages/requerimento/visualizar";
import TermoListar from "pages/termo/listas/todos";
import TermoListarAguardando from "pages/termo/listas/aguardando";
import TermoListarAprovados from "pages/termo/listas/aprovado";
import TermoListarCancelado from "pages/termo/listas/cancelado";
import ListarTermosFinalizados from "pages/termo/listas/finalizado";
import ListarTermosPendentes from "pages/termo/listas/pendente";
import ListarTermosReprovados from "pages/termo/listas/reprovado";
import TermoListarSolicitacaoCancelamento from "pages/termo/listas/solicitacaoCancelamento";
import TermoSolicitarEdicao from "pages/termo/solicitarEdicao";
import EditarTermo from "pages/termo/editar";
import TermoAdicionar from "./pages/termo/adicionar";
import EditarTermoSemAssinatura from "pages/termo/editarPronto";
import VisualizarTermo from "./pages/termo/visualizar";
import NotFound from "layout/NotFoundLayout";
import RequerimentoListarRollback from "pages/requerimento/listas/rollback";
import TermoListarRollback from "./pages/termo/listas/rollback";
import UsuarioCracha from "pages/usuario/cracha";
import TermoListarSolicitacaoFinalizacao from "pages/termo/listas/solicitacaoFinalizacao";
import ListarTermosReprovadosFinalizacao from "./pages/termo/listas/reprovadoFinalizacao";
import ListarRequerimentosFinalizados from "pages/requerimento/listas/finalizado";
import UserMiddleware from "./middlewares/UserMiddleware";

export default function AppRouter() {
	return (
		<Router>
			<Routes>
				<Route path={"/"} element={<DefaultLayoutNoLogin/>}>
					<Route index element={<Login/>}/>
					<Route path={ERotas.ESQUECI_SENHA} element={<EsqueciSenha/>}/>
					<Route path={ERotas.PRIMEIRO_ACESSO} element={<UsuarioPrimeiroAcesso/>}/>
				</Route>

				<Route element={<UserMiddleware/>}>
					<Route element={<DefaultLayout/>}>
						<Route path={ERotas.PRINCIPAL} element={<Inicio/>}/>
						{/* CARTÓRIOS */}
						<Route path={ERotas.CARTORIOS_LISTAR} element={<ListarCartorios/>}/>

						{/* DADOS BANCÁRIOS */}
						<Route path={ERotas.DADOS_BANCARIOS_LISTAR} element={<DadoBancarioListar/>}/>

						{/* REQUERIMENTOS */}
						<Route path={ERotas.REQUERIMENTO_LISTAR_TODOS} element={<RequerimentoListar/>}/>
						<Route path={ERotas.REQUERIMENTO_LISTAR_AGUARDANDO} element={<RequerimentoListarAguardando/>}/>
						<Route path={ERotas.REQUERIMENTO_LISTAR_APROVADOS} element={<RequerimentoListarAprovado/>}/>
						<Route path={ERotas.REQUERIMENTO_LISTAR_CANCELADOS} element={<RequerimentoListarCancelado/>}/>
						<Route path={ERotas.REQUERIMENTO_LISTAR_FINALIZADOS} element={<ListarRequerimentosFinalizados/>}/>
						<Route path={ERotas.REQUERIMENTO_LISTAR_PENDENTES} element={<ListarRequerimentosPendentes/>}/>
						<Route path={ERotas.REQUERIMENTO_LISTAR_REPROVADOS} element={<ListarRequerimentosReprovados/>}/>
						<Route path={ERotas.REQUERIMENTO_LISTAR_ROLLBACK} element={<RequerimentoListarRollback/>}/>
						<Route path={ERotas.REQUERIMENTO_LISTAR_SOLICITACAO_CANCELAMENTO} element={<RequerimentoListarSolicitacaoCancelamento/>}/>
						<Route path={`${ERotas.REQUERIMENTO_EDITAR}/:token`} element={<RequerimentoEditar/>}/>
						<Route path={`${ERotas.REQUERIMENTO_SOLICITAR_EDICAO}/:token`} element={<RequerimentoSolicitarEdicao/>}/>
						<Route path={ERotas.REQUERIMENTO_ADICIONAR} element={<RequerimentoAdicionar/>}/>
						<Route path={`${ERotas.REQUERIMENTO_VISUALIZAR}/:token`} element={<RequerimentoVisualizar/>}/>

						{/* TERMOS */}
						<Route path={ERotas.TERMO_LISTAR_TODOS} element={<TermoListar/>}/>
						<Route path={ERotas.TERMO_LISTAR_AGUARDANDO} element={<TermoListarAguardando/>}/>
						<Route path={ERotas.TERMO_LISTAR_APROVADOS} element={<TermoListarAprovados/>}/>
						<Route path={ERotas.TERMO_LISTAR_CANCELADOS} element={<TermoListarCancelado/>}/>
						<Route path={ERotas.TERMO_LISTAR_FINALIZADOS} element={<ListarTermosFinalizados/>}/>
						<Route path={ERotas.TERMO_LISTAR_PENDENTES} element={<ListarTermosPendentes/>}/>
						<Route path={ERotas.TERMO_LISTAR_REPROVADOS} element={<ListarTermosReprovados/>}/>
						<Route path={ERotas.TERMO_LISTAR_REPROVADOS_FINALIZACAO} element={<ListarTermosReprovadosFinalizacao/>}/>
						<Route path={ERotas.TERMO_LISTAR_ROLLBACK} element={<TermoListarRollback/>}/>
						<Route path={ERotas.TERMO_LISTAR_SOLICITACAO_CANCELAMENTO} element={<TermoListarSolicitacaoCancelamento/>}/>
						<Route path={ERotas.TERMO_LISTAR_SOLICITACAO_FINALIZACAO} element={<TermoListarSolicitacaoFinalizacao/>}/>
						<Route path={`${ERotas.TERMO_SOLICITAR_EDICAO}/:token`} element={<TermoSolicitarEdicao/>}/>
						<Route path={`${ERotas.TERMO_EDITAR}/:token`} element={<EditarTermo/>}/>
						<Route path={`${ERotas.TERMO_ADICIONAR}`} element={<TermoAdicionar/>}/>
						<Route path={`${ERotas.TERMO_EDITAR_PRONTO}/:token`} element={<EditarTermoSemAssinatura/>}/>
						<Route path={`${ERotas.TERMO_VISUALIZAR}/:token`} element={<VisualizarTermo/>}/>

						{/* USUÁRIOS */}
						<Route path={ERotas.USUARIO_ADICIONAR} element={<UsuarioAdicionar/>}/>
						<Route path={`${ERotas.USUARIO_EDITAR}/:token`} element={<UsuarioEditar/>}/>
						<Route path={ERotas.USUARIO_LISTAR} element={<UsuarioListar/>}/>
					</Route>
				</Route>

				<Route path={`${ERotas.USUARIO_CRACHA}/:token`} element={<UsuarioCracha/>}/>

				<Route path={"*"} element={<NotFound/>}/>
			</Routes>
		</Router>
	);
}
