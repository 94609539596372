import style from "./Image.module.scss";
import ModalImage from "react-modal-image";
import classnames from "classnames";
import ImageProps from "./_interfaces/ImageProps";

const Image = (props: ImageProps) => {
	const {
		image
	} = props;

	return (
		<div className={classnames(`justify-center text-center w-full ${style.container}`)}>
			<ModalImage small={image} large={image} hideZoom={true} className={style.image}/>
		</div>
	);
};

export default Image;