import {PhotoIcon} from "@heroicons/react/24/outline";
import {Dispatch, SetStateAction, useState} from "react";
import {ToastHelper} from "helpers/ToastHelper";
import {BaseHelper} from "helpers/BaseHelper";
import PdfDragDropProps from "./_interfaces/PdfDragDropProps";

const InputDropDragPdf = (props: PdfDragDropProps) => {
	const {setFiles, multiple} = props;
	const hash = BaseHelper.GetToken();

	const [dragging, setDragging] = useState<boolean>(false);

	const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setDragging(true);
	};

	const handleDragLeave = () => {
		setDragging(false);
	};

	const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
	};

	const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.persist();
		setDragging(false);

		handleChangeFile(e.dataTransfer.files);
	};

	const handleChangeFile = (files: FileList | null) => {
		if (files) {
			const filesArray = Array.from(files);

			filesArray.forEach((file) => {
				// SE ACEITAR UM TIPO ESPECÍFICO
				if (file.type !== "application/pdf") {
					ToastHelper("warning", "Por favor, selecione um arquivo válido.");
					return;
				}

				if (file.size > 15 * 1024 * 1024) {
					ToastHelper("warning", "Por favor, selecione um arquivo que não exceda 15MB.");
				}
			});

			if (multiple) {
				setFiles((prevFiles) => [...prevFiles, ...filesArray]);

				return;
			}

			setFiles([]);
			setFiles(filesArray);
		}
	};

	return (
		<div
			className={`flex justify-center sm:col-span-12 rounded-lg border border-dashed border-gray-900/25 px-6 py-10 ${dragging ? "bg-blue-100" : ""}`}
			onDragEnter={handleDragEnter}
			onDragLeave={handleDragLeave}
			onDragOver={handleDragOver}
			onDrop={handleDrop}>
			<div className="text-center">
				<PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true"/>
				<div className="mt-4 flex text-sm leading-6 text-gray-600">
					<label htmlFor={hash} className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
						<span>Selecione o Arquivo</span>
						<input
							id={hash}
							name={`${hash}[]`}
							type={"file"}
							accept={"application/pdf"}
							className={"sr-only"}
							multiple={multiple}
							onChange={(e) => handleChangeFile(e.target.files)}
						/>
					</label>
					<p className="pl-1">ou arraste e solte ele aqui!</p>
				</div>
				<p className="text-xs leading-5 text-gray-600">PDF de até 15MB</p>
			</div>
		</div>
	);
};

export default InputDropDragPdf;