import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {Breadcrumbs, Card, CardBody} from "@material-tailwind/react";
import UsuarioFormulario from "../_components/formulario";
import ButtonDefault from "components/Buttons/Default";
import EAcesso from "enums/EAcesso";
import EStrings from "enums/EStrings";
import {ToastHelper} from "helpers/ToastHelper";
import useData from "../../../contexts/dataProvider/useData";

const UsuarioAdicionar = () => {
	const navigate = useNavigate();
	const {auth} = useData();
	const acesso = auth?.acesso?.toString();

	useEffect(() => {
		if (acesso != EAcesso.MASTER.toString()) {
			ToastHelper("warning", EStrings.USUARIO_NAO_AUTENTICADO_ACAO);

			return navigate(-1);
		}
	}, [acesso]);

	return (
		<>
			<div className="flex w-full flex-row flex-wrap items-center border-blue-gray-50 text-center justify-between mb-5">
				<Breadcrumbs>
					<a onClick={() => navigate(-1)} className="opacity-60"> Usuários </a>
					<a className="opacity-60"> Adicionar </a>
				</Breadcrumbs>
				<ButtonDefault onClick={() => navigate(-1)} description={"Voltar"}/>
			</div>
			<Card className="w-100">
				<CardBody className="flex flex-col gap-4">
					<UsuarioFormulario textoCriarAtualizar={"Adicionar Usuário"} novo={true}/>
				</CardBody>
			</Card>
		</>
	);
};

export default UsuarioAdicionar;