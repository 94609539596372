import {Button, MenuItem, Navbar, Typography, Avatar} from "@material-tailwind/react";
import {FaBars} from "react-icons/fa";
import React, {useState} from "react";
import styles from "./Header.module.scss";
import Menu, {MenuHandler, MenuList} from "@material-tailwind/react/components/Menu";
import {ToastHelper} from "helpers/ToastHelper";
import {useNavigate} from "react-router-dom";
import {BiPowerOff, BiUserCircle} from "react-icons/bi";
import ERotas from "enums/ERotas";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import SpinnerComponent from "components/Spinner";
import {UsuariosService} from "services/UsuariosService";
import EStrings from "../../enums/EStrings";
import HeaderProps from "./_interfaces/HeaderProps";
import useData from "../../contexts/dataProvider/useData";
import {BaseHelper} from "../../helpers/BaseHelper";

const Header = (props: HeaderProps) => {
	const {
		setOpenMenu
	} = props;

	const navigate = useNavigate();
	const {user, auth} = useData();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const handleDeslogar = async () => {
		setIsLoading(true);

		try {
			const result = await UsuariosService.logout(auth?.token ?? "");

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);
				setIsLoading(false);
				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);
					setIsLoading(false);
					return;
				}

				ToastHelper("warning", errors[0].message);
				setIsLoading(false);
				return;
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);
				setIsLoading(false);
				return;
			}

			LogErrorHelper.redirectToLogin("success", "Usuário deslogado com sucesso.");
			setIsLoading(false);

			return navigate(ERotas.LOGIN);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
				setIsLoading(false);
				return;
			}

			ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			setIsLoading(false);
			return;
		}
	};

	const handleDirecionarPagina = (rota: string) => {
		navigate(rota);
	};

	return (
		<>
			{isLoading &&
				<SpinnerComponent/>
			}
			<Navbar className={styles.navbar}>
				<div className={styles.navbar__div}>
					<Button
						className={styles.navbar__div__button}
						onClick={() => setOpenMenu(true)}
						color={"amber"}
						variant={"gradient"}
					><FaBars/></Button>

					<Menu>
						<MenuHandler>
							<div className={"d-grid text-right"}>
								{user?.nome} {user?.sobrenome}
								<Avatar
									variant="circular"
									alt="Foto"
									className={styles.navbar__div__menu__menu__handler__avatar}
									src={BaseHelper.isBase64(user?.foto) ? `data:image/png;base64,${user?.foto}` : user?.foto ?? undefined}
								/>
							</div>
						</MenuHandler>
						<MenuList>
							<MenuItem className="flex items-center gap-2" onClick={() => {
								handleDirecionarPagina(`${ERotas.USUARIO_EDITAR}/${user?.token}`);
							}}>
								<BiUserCircle className={"h-4 w-4"}/>
								<Typography variant="small" className="font-normal">
									Meu Perfil
								</Typography>
							</MenuItem>
							<hr className="my-2 border-blue-gray-50"/>
							<MenuItem className="flex items-center gap-2" onClick={handleDeslogar}>
								<BiPowerOff color={"red"} className={"h-4 w-4"}/>
								<Typography variant="small" className="font-normal" color={"red"}>
									Sair
								</Typography>
							</MenuItem>
						</MenuList>
					</Menu>
				</div>
			</Navbar>
		</>
	);
};

export default Header;