import StorageHelper from "../../../helpers/StorageHelper";

interface UserResult {
	nome: string;
	sobrenome: string | null;
	token: string;
	acesso: number;
	ceo: boolean;
	foto: string | null;
	aceito_termo: boolean;
}

const deserializeUser = (): UserResult | null => {
	const stored = StorageHelper.get(localStorage, "user");

	if (stored) {
		try {
			return stored;
		} catch (e) {
			console.error("Erro ao desserializar user:", e);
		}
	}

	return null;
};

export {deserializeUser};
export type {UserResult};