import {IBuscarComboboxResult} from "interfaces/CommandsResults/ParametrosResults/IBuscarComboboxResult";

interface RequerimentoCommand {
	tokenCartorio: string | null,
	tokenCartorioObject: IBuscarComboboxResult | null,
	cepCartorio: string | null,
	enderecoCartorio: string | null,
	numeroCartorio: string | null,
	complementoCartorio: string | null,
	bairroCartorio: string | null,
	cidadeCartorio: string | null,
	ufCartorio: string | null,
	ufCartorioObject: IBuscarComboboxResult | null,
	dataEvento: Date | null,
	horaEvento: string | null,
	nomeLocal: string | null,
	cepLocal: string | null,
	enderecoLocal: string | null,
	numeroLocal: string | null,
	complementoLocal: string | null,
	bairroLocal: string | null,
	cidadeLocal: string | null,
	ufLocal: string | null,
	ufLocalObject: IBuscarComboboxResult | null
}

const createRequerimentoDefault = (overrides: Partial<RequerimentoCommand>): RequerimentoCommand => ({
	tokenCartorio: null,
	tokenCartorioObject: null,
	cepCartorio: null,
	enderecoCartorio: null,
	numeroCartorio: null,
	complementoCartorio: null,
	bairroCartorio: null,
	cidadeCartorio: null,
	ufCartorio: null,
	ufCartorioObject: null,
	dataEvento: null,
	horaEvento: null,
	nomeLocal: null,
	cepLocal: null,
	enderecoLocal: null,
	numeroLocal: null,
	complementoLocal: null,
	bairroLocal: null,
	cidadeLocal: null,
	ufLocal: null,
	ufLocalObject: null,
	...overrides
});

export {createRequerimentoDefault};
export type {RequerimentoCommand};