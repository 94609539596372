import {Typography} from "@material-tailwind/react";
import {DataHelper} from "../../helpers/DataHelper";
import {useEffect, useState} from "react";
import {ToastHelper} from "../../helpers/ToastHelper";
import EStrings from "../../enums/EStrings";
import {VersoesService} from "../../services/VersoesService";
import FooterProps from "./_interfaces/FooterProps";
import useData from "../../contexts/dataProvider/useData";

const Footer = (props: FooterProps) => {
	const {
		setLoading
	} = props;

	const {auth} = useData();
	const token = auth?.token;

	const [versao, setVersao] = useState<string>("0.0.0");

	useEffect(() => {
		buscarVersao();
	}, [0]);

	const buscarVersao = async () => {
		try {
			const result = await VersoesService.buscarAtivo(token ?? "");

			if (!result) {
				ToastHelper("error", EStrings.ERRO_BUSCAR_DADOS);

				return;
			}

			setVersao(result.data.data.versao);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
				return;
			}

			ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			return;
		} finally {
			setLoading(false);
		}
	};

	return (
		<footer className="flex w-full flex-row flex-wrap items-center justify-between gap-y-6 gap-x-12 border-t mt-6 border-blue-gray-50 py-6 text-center pl-5 pr-5">
			<Typography color="blue-gray" className="font-normal">
				&copy; {DataHelper.getNow().getFullYear()} ICRD
			</Typography>

			<Typography color="blue-gray" className="font-normal">
				v{versao}
			</Typography>
		</footer>
	);
};

export default Footer;