import {IListarTimelineTermoCommand} from "interfaces/Commands/TermosCommands/IListarTimelineTermoCommand";
import {IListarTimelineTermoResult} from "interfaces/CommandsResults/TermosResults/IListarTimelineTermoResult";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {TermosService} from "services/TermosService";
import {ToastHelper} from "helpers/ToastHelper";
import EStrings from "enums/EStrings";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import SpinnerComponent from "components/Spinner";
import {Accordion, AccordionBody, AccordionHeader, Drawer, Timeline, TimelineBody, TimelineConnector, TimelineHeader, TimelineIcon, TimelineItem, Typography} from "@material-tailwind/react";
import {HiOutlineStar} from "react-icons/hi2";
import {Icons} from "../../../../components/Icons";
import useData from "../../../../contexts/dataProvider/useData";

interface IProps {
	termo: string;
	nomePrimeiroNubente: string;
	nomeSegundoNubente: string;
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
}

const TermoTimeline = (props: IProps) => {
	const {
		termo,
		nomePrimeiroNubente,
		nomeSegundoNubente,
		open,
		setOpen
	} = props;

	const {auth} = useData();
	const tokenVisao = auth?.token;
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [data, setData] = useState<IListarTimelineTermoResult | null>(null);

	const [openAccordion, setOpenAccordion] = useState<number>(0);

	useEffect(() => {
		if (open) {
			listarTimeline();
		}
	}, [open]);

	const listarTimeline = async () => {
		setIsLoading(true);

		try {
			const params: IListarTimelineTermoCommand = {
				token: termo
			};

			const result = await TermosService.listarTimeline(params, tokenVisao ?? "");

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);
				setIsLoading(false);
				setOpen(false);
				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);
					setIsLoading(false);
					setOpen(false);
					return;
				}

				ToastHelper("warning", errors[0].message);
				setIsLoading(false);
				setOpen(false);
				return;
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);
				setIsLoading(false);
				setOpen(false);
				return;
			}

			setData(body.data);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
				setIsLoading(false);
				setOpen(false);
				return;
			}

			ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			setIsLoading(false);
			setOpen(false);
			return;
		}

		setIsLoading(false);
	};

	const rendererTimeline = (index: number, usuario: string, dataHora: string, mensagem: string) => {
		return <TimelineItem key={index}>
			<TimelineConnector/>
			<TimelineHeader className="h-3">
				<TimelineIcon className="p-2">
					<HiOutlineStar/>
				</TimelineIcon>
				<Typography variant="h6" color="blue-gray" className="leading-none">
					{usuario ?? "USUÁRIO NÃO IDENTIFICADO"}
				</Typography>
			</TimelineHeader>
			<TimelineBody className="pb-8">
				<Typography variant={"small"} color={"gray"} className={"font-normal"}>
					Data/Hora: {dataHora ?? ""}
				</Typography>
				<Typography variant="small" color="gary" className="font-normal text-gray-600">
					{mensagem ?? ""}
				</Typography>
			</TimelineBody>
		</TimelineItem>;
	};

	const handleOpen = (value: number) => setOpenAccordion(openAccordion === value ? 0 : value);

	return (
		<>
			{isLoading &&
				<SpinnerComponent/>
			}
			<Drawer open={open} onClose={() => setOpen(!open)} className="h-[calc(100vh-2rem)] w-full max-w-[25rem] p-4 shadow-xl shadow-blue-gray-900/5 overflow-y-auto" placement={"right"} size={350}>
				<div className="mb-6 flex items-center justify-between">
					<Typography variant="h5" color="blue-gray">
						📌 Timeline
					</Typography>
				</div>

				<Accordion open={openAccordion === 1} icon={Icons.arrow(1, openAccordion)}>
					<AccordionHeader onClick={() => handleOpen(1)}>Termo</AccordionHeader>
					<AccordionBody>
						<Timeline>
							{data?.termo.map((item, index) => {
								return rendererTimeline(index, item.usuario, item.data_hora_registro, item.mensagem);
							})}
						</Timeline>
					</AccordionBody>
				</Accordion>
				<Accordion open={openAccordion === 2} icon={Icons.arrow(2, openAccordion)}>
					<AccordionHeader onClick={() => handleOpen(2)}>
						{nomePrimeiroNubente}
					</AccordionHeader>
					<AccordionBody>
						<Timeline>
							{data?.nubente.primeiro.map((item, index) => {
								return rendererTimeline(index, item.usuario, item.data_hora_registro, item.mensagem);
							})}
						</Timeline>
					</AccordionBody>
				</Accordion>
				<Accordion open={openAccordion === 3} icon={Icons.arrow(3, openAccordion)}>
					<AccordionHeader onClick={() => handleOpen(3)}>
						{nomeSegundoNubente}
					</AccordionHeader>
					<AccordionBody>
						<Timeline>
							{data?.nubente.segundo.map((item, index) => {
								return rendererTimeline(index, item.usuario, item.data_hora_registro, item.mensagem);
							})}
						</Timeline>
					</AccordionBody>
				</Accordion>
			</Drawer>
		</>
	);
};

export default TermoTimeline;