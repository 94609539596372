import {Api} from "providers/Api";
import {ApiResult} from "interfaces/ApiResult";
import {IListarRequerimentoResult} from "interfaces/CommandsResults/RequerimentosResults/IListarRequerimentoResult";
import IListarRequerimentosCommand from "interfaces/Commands/RequerimentosCommands/IListarRequerimentosCommand";
import IDownloadRequerimentoAprovadoCommand from "interfaces/Commands/RequerimentosCommands/IDownloadRequerimentoAprovadoCommand";
import IDownloadRequerimentoAprovadoResult from "interfaces/CommandsResults/RequerimentosResults/IDownloadRequerimentoAprovadoResult";
import IDownloadRequerimentoComprovanteCommand from "interfaces/Commands/RequerimentosCommands/IDownloadRequerimentoComprovanteCommand";
import IDownloadRequerimentoComprovanteResult from "interfaces/CommandsResults/RequerimentosResults/IDownloadRequerimentoComprovanteResult";
import ISolicitarCancelamentoRequerimentoCommand from "../interfaces/Commands/RequerimentosCommands/ISolicitarCancelamentoRequerimentoCommand";
import ISolicitarCancelamentoRequerimentoResult from "interfaces/CommandsResults/RequerimentosResults/ISolicitarCancelamentoRequerimentoResult";
import IReprovarCancelamentoRequerimentoCommand from "interfaces/Commands/RequerimentosCommands/IReprovarCancelamentoRequerimentoCommand";
import IReprovarCancelamentoRequerimentoResult from "interfaces/CommandsResults/RequerimentosResults/IReprovarCancelamentoRequerimentoResult";
import IAprovarCancelamentoRequerimentoCommand from "interfaces/Commands/RequerimentosCommands/IAprovarCancelamentoRequerimentoCommand";
import IAprovarCancelamentoRequerimentoResult from "interfaces/CommandsResults/RequerimentosResults/IAprovarCancelamentoRequerimentoResult";
import IAprovarEdicaoRequerimentoResult from "interfaces/CommandsResults/RequerimentosResults/IAprovarEdicaoRequerimentoResult";
import IAprovarEdicaoRequerimentoCommand from "interfaces/Commands/RequerimentosCommands/IAprovarEdicaoRequerimentoCommand";
import IReprovarEdicaoRequerimentoCommand from "interfaces/Commands/RequerimentosCommands/IReprovarEdicaoRequerimentoCommand";
import IReprovarEdicaoRequerimentoResult from "interfaces/CommandsResults/RequerimentosResults/IReprovarEdicaoRequerimentoResult";
import IValidarSolicitacaoEdicaoRequerimentoCommand from "interfaces/Commands/RequerimentosCommands/IValidarSolicitacaoEdicaoRequerimentoCommand";
import IValidarSolicitacaoEdicaoRequerimentoResult from "interfaces/CommandsResults/RequerimentosResults/IValidarSolicitacaoEdicaoRequerimentoResult";
import ISalvarComprovanteEdicaoRequerimentoCommand from "interfaces/Commands/RequerimentosCommands/ISalvarComprovanteEdicaoRequerimentoCommand";
import ISalvarComprovanteEdicaoRequerimentoResult from "interfaces/CommandsResults/RequerimentosResults/ISalvarComprovanteEdicaoRequerimentoResult";
import IBuscarRequerimentoCommand from "interfaces/Commands/RequerimentosCommands/IBuscarRequerimentoCommand";
import {IBuscarRequerimentoResult} from "interfaces/CommandsResults/RequerimentosResults/IBuscarRequerimentoResult";
import ISalvarAtualizarRequerimentoCommand from "interfaces/Commands/RequerimentosCommands/ISalvarAtualizarRequerimentoCommand";
import ISalvarAtualizarRequerimentoResult from "interfaces/CommandsResults/RequerimentosResults/ISalvarAtualizarRequerimentoResult";
import IBuscarModeloPdfRequerimentoCommand from "interfaces/Commands/RequerimentosCommands/IBuscarModeloPdfRequerimentoCommand";
import IBuscarModeloPdfRequerimentoResult from "interfaces/CommandsResults/RequerimentosResults/IBuscarModeloPdfRequerimentoResult";
import IBuscarPdfRequerimentoCommand from "interfaces/Commands/RequerimentosCommands/IBuscarPdfRequerimentoCommand";
import IBuscarPdfRequerimentoResult from "interfaces/CommandsResults/RequerimentosResults/IBuscarPdfRequerimentoResult";
import IReprovarRequerimentoCommand from "interfaces/Commands/RequerimentosCommands/IReprovarRequerimentoCommand";
import IReprovarRequerimentoResult from "interfaces/CommandsResults/RequerimentosResults/IReprovarRequerimentoResult";
import IAprovarRequerimentoCommand from "interfaces/Commands/RequerimentosCommands/IAprovarRequerimentoCommand";
import IAprovarRequerimentoResult from "interfaces/CommandsResults/RequerimentosResults/IAprovarRequerimentoResult";
import IValidarCriacaoRequerimentoResult from "interfaces/CommandsResults/RequerimentosResults/IValidarCriacaoRequerimentoResult";
import IRequerimentosAprovadosSemTermoResult from "interfaces/CommandsResults/RequerimentosResults/IRequerimentosAprovadosSemTermoResult";
import IAprovarRollbackRequerimentoCommand from "interfaces/Commands/RequerimentosCommands/IAprovarRollbackRequerimentoCommand";
import IAprovarRollbackRequerimentoResult from "interfaces/CommandsResults/RequerimentosResults/IAprovarRollbackRequerimentoResult";
import IListarTimelineRequerimentoCommand  from "interfaces/Commands/RequerimentosCommands/IListarTimelineRequerimentoCommand";
import IListarTimelineRequerimentoResult from "interfaces/CommandsResults/RequerimentosResults/IListarTimelineRequerimentoResult";

const controller = "/requerimentos";

const listar = (data: IListarRequerimentosCommand, token: string) => Api.post<ApiResult<IListarRequerimentoResult[]>>(`${controller}/listar`, data, {
	headers: {
		token: token
	}
});
const downloadAprovado = (data: IDownloadRequerimentoAprovadoCommand, token: string) => Api.post<ApiResult<IDownloadRequerimentoAprovadoResult>>(`${controller}/downloadAprovado`, data, {
	headers: {
		token: token
	}
});
const downloadComprovante = (data: IDownloadRequerimentoComprovanteCommand, token: string) => Api.post<ApiResult<IDownloadRequerimentoComprovanteResult>>(`${controller}/downloadComprovante`, data, {
	headers: {
		token: token
	}
});
const solicitarCancelamento = (data: ISolicitarCancelamentoRequerimentoCommand, token: string) => Api.post<ApiResult<ISolicitarCancelamentoRequerimentoResult>>(`${controller}/solicitarCancelamento`, data, {
	headers: {
		token: token
	}
});
const reprovarCancelamento = (data: IReprovarCancelamentoRequerimentoCommand, token: string) => Api.post<ApiResult<IReprovarCancelamentoRequerimentoResult>>(`${controller}/reprovarCancelamento`, data, {
	headers: {
		token: token
	}
});
const aprovarCancelamento = (data: IAprovarCancelamentoRequerimentoCommand, token: string) => Api.post<ApiResult<IAprovarCancelamentoRequerimentoResult>>(`${controller}/aprovarCancelamento`, data, {
	headers: {
		token: token
	}
});
const aprovarEdicao = (data: IAprovarEdicaoRequerimentoCommand, token: string) => Api.post<ApiResult<IAprovarEdicaoRequerimentoResult>>(`${controller}/aprovarEdicao`, data, {
	headers: {
		token: token
	}
});
const reprovarEdicao = (data: IReprovarEdicaoRequerimentoCommand, token: string) => Api.post<ApiResult<IReprovarEdicaoRequerimentoResult>>(`${controller}/reprovarEdicao`, data, {
	headers: {
		token: token
	}
});
const validarSolicitacaoEdicao = (data: IValidarSolicitacaoEdicaoRequerimentoCommand, token: string) => Api.post<ApiResult<IValidarSolicitacaoEdicaoRequerimentoResult>>(`${controller}/validarEdicao`, data, {
	headers: {
		token: token
	}
});
const salvarComprovanteEdicao = (data: ISalvarComprovanteEdicaoRequerimentoCommand, token: string) => Api.post<ApiResult<ISalvarComprovanteEdicaoRequerimentoResult>>(`${controller}/salvarComprovanteEdicao`, data, {
	headers: {
		token: token
	}
});
const buscar = (data: IBuscarRequerimentoCommand, token: string) => Api.post<ApiResult<IBuscarRequerimentoResult>>(`${controller}/buscar`, data, {
	headers: {
		token: token
	}
});
const salvarAtualizar = (data: ISalvarAtualizarRequerimentoCommand, token: string) => Api.post<ApiResult<ISalvarAtualizarRequerimentoResult>>(`${controller}/salvarAtualizar`, data, {
	headers: {
		token: token
	}
});
const buscarModeloPdf = (data: IBuscarModeloPdfRequerimentoCommand, token: string) => Api.post<ApiResult<IBuscarModeloPdfRequerimentoResult>>(`${controller}/buscarModelo`, data, {
	headers: {
		token: token
	}
});
const buscarPdf = (data: IBuscarPdfRequerimentoCommand, token: string) => Api.post<ApiResult<IBuscarPdfRequerimentoResult>>(`${controller}/buscarPdf`, data, {
	headers: {
		token: token
	}
});
const reprovar = (data: IReprovarRequerimentoCommand, token: string) => Api.post<ApiResult<IReprovarRequerimentoResult>>(`${controller}/reprovar`, data, {
	headers: {
		token: token
	}
});
const aprovar = (data: IAprovarRequerimentoCommand, token: string) => Api.post<ApiResult<IAprovarRequerimentoResult>>(`${controller}/aprovar`, data, {
	headers: {
		token: token
	}
});
const validarCriacao = (token: string) => Api.post<ApiResult<IValidarCriacaoRequerimentoResult>>(`${controller}/validarCriacao`, {}, {
	headers: {
		token: token
	}
});
const listarAprovadosSemTermo = (token: string) => Api.post<ApiResult<IRequerimentosAprovadosSemTermoResult[]>>(`${controller}/listarAprovadosSemTermo`, {}, {
	headers: {
		token: token
	}
});
const listarTimeline = (data: IListarTimelineRequerimentoCommand, token: string) => Api.post<ApiResult<IListarTimelineRequerimentoResult>>(`${controller}/listarTimeline`, data, {
	headers: {
		token: token
	}
});
const listarRollback = (token: string) => Api.post<ApiResult<IListarRequerimentoResult[]>>(`${controller}/listarRollback`, {}, {
	headers: {
		token: token
	}
});
const aprovarRollback = (data: IAprovarRollbackRequerimentoCommand, token: string) => Api.post<ApiResult<IAprovarRollbackRequerimentoResult>>(`${controller}/aprovarRollback`, data, {
	headers: {
		token: token
	}
});

export const RequerimentosService = {
	listar,
	downloadAprovado,
	downloadComprovante,
	solicitarCancelamento,
	reprovarCancelamento,
	aprovarCancelamento,
	aprovarEdicao,
	reprovarEdicao,
	validarSolicitacaoEdicao,
	salvarComprovanteEdicao,
	buscar,
	salvarAtualizar,
	buscarModeloPdf,
	buscarPdf,
	reprovar,
	aprovar,
	validarCriacao,
	listarAprovadosSemTermo,
	listarRollback,
	aprovarRollback,
	listarTimeline
};