import {Alert, Card, CardBody, Typography} from "@material-tailwind/react";
import {HiOutlineExclamation} from "react-icons/hi";

interface IProps {
	motivo_reprovacao: string | undefined;
}

const TermoAlertaMotivoReprovacaoFinalizacao = (props: IProps) => {
	const {
		motivo_reprovacao
	} = props;
	
	return (
		<Card className={"w-full"}>
			<CardBody className={"w-full m-0 p-0 space-y-2"}>
				<Alert color={"red"} className={"w-full space-x-2 text-center flex items-center justify-center"} variant={"gradient"} icon={<HiOutlineExclamation/>}>
					<Typography className="font-medium">
						Motivo da Reprovação
					</Typography>
				</Alert>
				<div className="card-body row text-center p-5 space-y-2">
					<Typography className={"font-semibold"}>{motivo_reprovacao ?? ""}</Typography>
				</div>
			</CardBody>
		</Card>
	);
};

export default TermoAlertaMotivoReprovacaoFinalizacao;