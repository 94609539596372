import {IBuscarTermoSemAssinaturaResult} from "interfaces/CommandsResults/TermosResults/IBuscarTermoSemAssinaturaResult";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import EAcesso from "enums/EAcesso";
import {ToastHelper} from "helpers/ToastHelper";
import EStrings from "enums/EStrings";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import ERotas from "enums/ERotas";
import {IBuscarTermoCommand} from "interfaces/Commands/TermosCommands/IBuscarTermoCommand";
import {TermosService} from "services/TermosService";
import {EStatusTermo} from "enums/EStatusTermo";
import SpinnerComponent from "components/Spinner";
import {Alert, Breadcrumbs, Card, CardBody, Typography} from "@material-tailwind/react";
import ButtonDefault from "components/Buttons/Default";
import {Icons} from "components/Icons";
import TermoFormularioSemAssinatura from "../_components/formularios/semAssinatura";
import TermoTimeline from "../_components/timelineComponent";
import useData from "../../../contexts/dataProvider/useData";

const EditarTermoSemAssinatura = () => {
	const navigate = useNavigate();
	const {token} = useParams();
	const {auth} = useData();

	const tokenVisao = auth?.token;
	const acesso = auth?.acesso?.toString();

	const [isLoadingIndex, setIsLoadingIndex] = useState<boolean>(false);
	const [data, setData] = useState<IBuscarTermoSemAssinaturaResult>();

	const [openTimeline, setOpenTimeline] = useState<boolean>(false);

	useEffect(() => {
		buscarDados();
	}, []);

	useEffect(() => {
		if (acesso != EAcesso.CELEBRANTE.toString()) {
			ToastHelper("warning", EStrings.USUARIO_NAO_AUTENTICADO_ACAO);

			return navigate(-1);
		}
	}, [acesso]);

	const buscarDados = async () => {
		setIsLoadingIndex(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			navigate(ERotas.LOGIN);
			return;
		}

		if (!token || token === "") {
			ToastHelper("warning", EStrings.TOKEN_OBRIGATORIO);

			return navigate(-1);
		}

		try {
			setIsLoadingIndex(false);

			const params: IBuscarTermoCommand = {
				token: token
			};

			const result = await TermosService.buscarSemAssinatura(params, tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return navigate(-1);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					navigate(ERotas.LOGIN);
					return;
				}

				ToastHelper("warning", errors[0].message);

				return navigate(-1);
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return navigate(-1);
			}

			if (body.data.status !== EStatusTermo.PENDENTE && body.data.status !== EStatusTermo.AGUARDANDO && !body.data.is_termo_pronto) {
				ToastHelper("warning", EStrings.TERMO_NAO_AUTENTICADO_ACAO);

				navigate(-1);

				return;
			}

			setData(body.data);
		} catch (error) {
			setIsLoadingIndex(true);

			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return navigate(-1);
		}
	};

	return (
		<>
			{isLoadingIndex &&
				<SpinnerComponent/>
			}
			<div className="flex w-full flex-row flex-wrap items-center border-blue-gray-50 text-center justify-between mb-5">
				<Breadcrumbs>
					<a onClick={() => navigate(-1)} className="opacity-60"> Termos </a>
					<a className="opacity-60"> Editar </a>
				</Breadcrumbs>
				<ButtonDefault onClick={() => navigate(-1)} description={"Voltar"}/>
			</div>
			<Card className={"w-100"}>
				<CardBody className={"flex flex-col gap-4"}>
					{!isLoadingIndex &&
						<>
							{data?.motivo_reprova !== "" && data?.motivo_reprova !== undefined && data?.motivo_reprova !== null &&
								<Alert color={"green"} icon={Icons.alert()}>
									<Typography className={"font-medium"}>
										Segue abaixo o motivo da reprovação para correção:
									</Typography>
									<ul className={"mt-2 ml-2 list-inside list-disc"}>
										<li>{data?.motivo_reprova}</li>
									</ul>
								</Alert>
							}

							<ButtonDefault
								color={"teal"}
								onClick={() => setOpenTimeline(!openTimeline)}
								description={"Acessar Timeline"}
								variant={"gradient"}
							/>

							<TermoFormularioSemAssinatura textoCriarAtualizar={"Atualizar Termo"} novo={false} data={data}/>
						</>
					}
				</CardBody>
			</Card>

			<TermoTimeline
				termo={data?.token ?? ""}
				nomePrimeiroNubente={data?.nome_primeiro_nubente ?? ""}
				nomeSegundoNubente={data?.nome_segundo_nubente ?? ""}
				open={openTimeline}
				setOpen={setOpenTimeline}
			/>
		</>
	);
};

export default EditarTermoSemAssinatura;