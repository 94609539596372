import {ToastContainer as Toast} from "react-toastify";

const ToastContainer = () => {
	return (
		<Toast
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={true}
			closeOnClick={true}
			rtl={false}
			pauseOnFocusLoss={false}
			draggable={false}
			pauseOnHover={false}
			stacked
		/>
	);
};

export default ToastContainer;