import {FormEvent, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import styles from "./Login.module.scss";
import Input from "components/Inputs/Default";
import ButtonSubmit from "components/Buttons/Submit";
import ButtonDefault from "components/Buttons/Default";
import {ToastHelper} from "helpers/ToastHelper";
import {UsuariosService} from "services/UsuariosService";
import {createLoginDefault, ILoginCommand} from "interfaces/Commands/UsuariosCommands/ILoginCommand";
import EDeletado from "enums/EDeletado";
import EStrings from "enums/EStrings";
import ERotas from "enums/ERotas";
import {Checkbox} from "@material-tailwind/react";
import {IBuscarTokenizacaoCommand} from "../../interfaces/Commands/UsuariosCommands/IBuscarTokenizacaoCommand";
import PasswordComponent from "../../components/Inputs/Password";
import useData from "../../contexts/dataProvider/useData";
import Spinner from "../../components/Spinner";

const Login = () => {
	const navigate = useNavigate();

	const [data, setData] = useState<ILoginCommand>(createLoginDefault({}));
	const {setAuth} = useData();

	const [visiblePassword, setVisiblePassword] = useState<boolean>(false);

	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		const localToken = Cookies.get(EStrings.COOKIE);

		if (localToken !== undefined) {
			buscarTokenizacao(localToken);
		}
	}, [0]);

	const handleInputChange = (name: string, value: string | boolean) => {
		setData((prevState) => ({
			...prevState,
			[name]: value
		}));
	};

	const enviarFormulario = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setLoading(true);

		try {
			if (!data.email) {
				ToastHelper("warning", EStrings.EMAIL_OBRIGATORIO);
				return;
			}

			if (!data.senha) {
				ToastHelper("warning", EStrings.SENHA_OBRIGATORIO);
				return;
			}

			const result = await UsuariosService.login(data);

			if (!result) {
				ToastHelper("warning", EStrings.USUARIO_NAO_LOCALIZADO);
				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				ToastHelper("warning", errors[0].message);
				return;
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);
				return;
			}

			if (body.data.d_e_l_e_t_ === EDeletado.INATIVO) {
				ToastHelper("warning", EStrings.USUARIO_NAO_AUTENTICADO);
				return;
			}

			setAuth({
				token: body.data.token,
				email: body.data.email,
				acesso: body.data.acesso,
				quantidade_tentativas: 0
			});

			if (data.conectado) {
				Cookies.set(EStrings.COOKIE, body.data.token, {expires: 7});
			}

			navigate(ERotas.PRINCIPAL);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}
		} finally {
			setLoading(false);
		}
	};

	const handleAcessarPagina = (rota: string) => {
		navigate(rota);
	};

	const buscarTokenizacao = async (token: string) => {
		try {
			const params: IBuscarTokenizacaoCommand = {
				token: token
			};

			const result = await UsuariosService.buscarTokenizacao(params);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				Cookies.remove(EStrings.COOKIE);
				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				ToastHelper("warning", errors[0].message);

				Cookies.remove(EStrings.COOKIE);
				return;
			}

			const body = result.data;

			if (body == null) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				Cookies.remove(EStrings.COOKIE);
				return;
			}

			const data = body.data;

			if (data.d_e_l_e_t_ === EDeletado.INATIVO) {
				ToastHelper("warning", EStrings.USUARIO_NAO_AUTENTICADO);

				Cookies.remove(EStrings.COOKIE);
				return;
			}

			setAuth({
				token: body.data.token,
				email: body.data.email,
				acesso: body.data.acesso,
				quantidade_tentativas: 0
			});

			navigate(ERotas.PRINCIPAL);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return;
		}
	};

	return (
		<>
			{loading &&
				<Spinner/>
			}

			<form className={styles.form} id={"formLogin"} onSubmit={enviarFormulario} noValidate>
				<div className={"grid w-full sm:grid-cols-12 mt-4"}>
					<div className={"sm:col-span-12 mb-4"}>
						<Input
							type={"email"}
							label={"E-mail"}
							onChange={(e) => handleInputChange("email", e.target.value)}
							value={data.email}
							required={true}
							lowercase={true}
						/>
					</div>

					<div className={"sm:col-span-12 mb-4"}>
						<PasswordComponent
							label={"Senha"}
							onChange={(e) => handleInputChange("senha", e.target.value)}
							visible={visiblePassword}
							value={data.senha}
							setVisible={setVisiblePassword}
							required={true}
						/>
					</div>

					<div className={"sm:col-span-12 mb-4 mr-auto"}>
						<Checkbox crossOrigin={undefined} label={"Mantenha conectado"} color={"amber"} onChange={(e) => handleInputChange("conectado", e.target.checked)} className={"h-4 w-4 rounded-full border-gray-900/20 bg-gray-900/10 transition-all hover:scale-105 hover:before:opacity-0 ml-auto"}/>
					</div>

					<div className={"sm:col-span-12 mb-4"}>
						<ButtonSubmit form={"formLogin"} fullWidth={true} desabilitar={false} description={"Acessar"}/>
					</div>
				</div>

				<hr className={"mb-4"}/>

				<div className={"grid w-full sm:grid-cols-12"}>
					<div className={"sm:col-span-6 text-center"}>
						<ButtonDefault variant={"text"} description={"Esqueci minha senha"} onClick={() => handleAcessarPagina(ERotas.ESQUECI_SENHA)}/>
					</div>
					<div className={"sm:col-span-6 text-center"}>
						<ButtonDefault variant={"text"} description={"Primeiro Acesso"} onClick={() => handleAcessarPagina(ERotas.PRIMEIRO_ACESSO)}/>
					</div>
				</div>
			</form>
		</>
	);
};

export default Login;