import {Outlet} from "react-router-dom";
import styles from "./DefaultLayoutNoLogin.module.scss";
import {Card, CardBody} from "@material-tailwind/react";
import logo from "assets/logo.jpg";
import {useEffect} from "react";
import ToastContainer from "components/ToastContainer";

const DefaultLayoutNoLogin = () => {
	useEffect(() => {
		localStorage.clear();
		localStorage.clear();
	}, []);

	return (
		<main className={styles.container}>
			<Card shadow={true} className={styles.container__card}>
				<img src={logo} alt={"Logo da Escola de Celebrantes"} className={styles.container__card__imagem}/>
				<CardBody className={styles.container__card__body}>
					<Outlet/>
				</CardBody>
			</Card>
			<ToastContainer/>
		</main>
	);
};

export default DefaultLayoutNoLogin;