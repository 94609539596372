import {Dialog, DialogBody, DialogFooter, DialogHeader, Textarea, Typography} from "@material-tailwind/react";
import React, {Dispatch, FormEvent, SetStateAction, useState} from "react";
import ButtonDefault from "components/Buttons/Default";
import ERotas from "enums/ERotas";
import {useNavigate} from "react-router-dom";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import EStrings from "enums/EStrings";
import {ToastHelper} from "helpers/ToastHelper";
import ButtonSubmit from "components/Buttons/Submit";
import {ISolicitarCancelamentoTermoCommand} from "interfaces/Commands/TermosCommands/ISolicitarCancelamentoTermoCommand";
import {TermosService} from "services/TermosService";
import {IListarTermoResult} from "interfaces/CommandsResults/TermosResults/IListarTermoResult";
import {BaseHelper} from "helpers/BaseHelper";
import useData from "../../../../../contexts/dataProvider/useData";

interface IProps {
	termo?: IListarTermoResult;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setAtualizarLista: Dispatch<SetStateAction<boolean>>;
}

const TermoModalSolicitarCancelamento = (props: IProps) => {
	const {
		termo,
		open,
		setOpen,
		setAtualizarLista
	} = props;
	const navigate = useNavigate();
	const {auth} = useData();
	const tokenVisao = auth?.token;

	const [motivoCancelamento, setMotivoCancelamento] = useState<string>("");
	const [desabilitarBotao, setDesabilitarBotao] = useState<boolean>(false);

	const handleEnviarFormularioMotivoCancelamento = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setDesabilitarBotao(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			return navigate(ERotas.LOGIN);
		}

		try {
			const params: ISolicitarCancelamentoTermoCommand = {
				token: termo?.token ?? "",
				motivo_cancelamento: motivoCancelamento
			};

			const result = await TermosService.solicitarCancelamento(params, tokenVisao);

			if (!result) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				errors.forEach((item) => {
					ToastHelper("warning", item.message);
				});

				setDesabilitarBotao(false);
				return;
			}

			const body = result.data;

			if (!body) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return;
			}

			setDesabilitarBotao(false);
			setOpen(false);

			ToastHelper("success", "Solicitação de Cancelamento realizada com sucesso.");

			setAtualizarLista(true);
		} catch (error) {
			setDesabilitarBotao(false);

			if (error instanceof Error) {
				ToastHelper("error", error.message);
				setOpen(false);

				return;
			}

			ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			setOpen(false);

			return;
		}
	};

	return (
		<Dialog open={open} handler={() => setOpen(!open)} size={"sm"} animate={BaseHelper.AnimationDialog}>
			<DialogHeader>Solicitar Cancelamento # {termo?.id}</DialogHeader>
			<DialogBody>
				<Typography variant={"small"} className={"font-monospace text-justify"}>
					Digite abaixo o motivo de cancelamento.
				</Typography>
				&emsp;
				<form id={"formSolicitacaoCancelamento"} onSubmit={handleEnviarFormularioMotivoCancelamento} noValidate>
					<Textarea
						color={"blue"}
						label="Motivo do Cancelamento"
						value={motivoCancelamento}
						variant="outlined"
						size={"md"}
						required={true}
						onChange={(e) => setMotivoCancelamento(e.target.value)}
					/>
				</form>
			</DialogBody>
			<DialogFooter className={"space-x-2"}>
				<ButtonDefault color={"red"} description={"Fechar"} onClick={() => setOpen(false)} desabilitar={desabilitarBotao}/>
				<ButtonSubmit form={"formSolicitacaoCancelamento"} description={"Solicitar Cancelamento"} color={"green"} desabilitar={desabilitarBotao}/>
			</DialogFooter>
		</Dialog>
	);
};

export default TermoModalSolicitarCancelamento;