import {Api} from "providers/Api";
import {ApiResult} from "interfaces/ApiResult";
import DeletarDocumentoCommand from "interfaces/Commands/DocumentosCommands/DeletarDocumentoCommand";
import {IDeletarDocumentoResult} from "interfaces/CommandsResults/DocumentosResults/IDeletarDocumentoResult";
import ListarDocumentosTermoCommand from "interfaces/Commands/DocumentosCommands/ListarDocumentosTermoCommand";
import {IListarDocumentosTermoResult} from "interfaces/CommandsResults/DocumentosResults/IListarDocumentosTermoResult";

const controller = "/documentos";

const listarDocumentosTermo = (data: ListarDocumentosTermoCommand, token: string) => Api.post<ApiResult<IListarDocumentosTermoResult[]>>(`${controller}/listarDocumentosTermo`, data, {
	headers: {
		token: token
	}
});
const deletar = (data: DeletarDocumentoCommand, token: string) => Api.post<ApiResult<IDeletarDocumentoResult>>(`${controller}/deletar`, data, {
	headers: {
		token: token
	}
});

export const DocumentosService = {
	listarDocumentosTermo,
	deletar
};