import {Alert, Card, CardBody, Typography} from "@material-tailwind/react";
import {HiOutlineExclamation} from "react-icons/hi";

interface IProps {
	valorPix: string;
}

const TermoAlertaSolicitacaoEdicao = (props: IProps) => {
	const {valorPix} = props;

	return (
		<Card className={"w-full"}>
			<CardBody className={"w-full m-0 p-0 space-y-2"}>
				<Alert color={"amber"} className={"w-full space-x-2 text-center flex items-center justify-center"} variant={"gradient"} icon={<HiOutlineExclamation/>}>
					<Typography className="font-medium">
						Atenção!
					</Typography>
				</Alert>
				<div className="card-body row text-center p-5 space-y-2">
					<Typography className={"font-semibold"}>EDIÇÃO PARA ESTE TERMO.</Typography>
					<Typography className={"font-monospace"}>PARA EDIÇÃO, POR GENTILEZA, REALIZE A TRANSFERÊNCIA NA QUANTIA DE R$ {valorPix} PARA A ADMINISTRAÇÃO E ANEXE O COMPROVANTE NO FORMULÁRIO ABAIXO</Typography>
				</div>
			</CardBody>
		</Card>
	);
};

export default TermoAlertaSolicitacaoEdicao;